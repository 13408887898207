import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const POST_FARM_SEASON_CROPS_LOADING = 'POST_FARM_SEASON_CROPS_LOADING';
export const POST_FARM_SEASON_CROPS_FAILED = 'POST_FARM_SEASON_CROPS_FAILED';
export const POST_FARM_SEASON_CROPS_SUCCESS = 'POST_FARM_SEASON_CROPS_SUCCESS';

export type CropT = {
    id: number;
    translation_slug?: string;
    name?: string;
};

export type FarmSeasonCropT = {
    id?: number | null;
    yield?: string | null;
    is_accompanied?: boolean | null;
    crop: CropT;
};

export type PostFarmSeasonCropsDataT = {
    crops: FarmSeasonCropT[];
};

export type PostFarmSeasonCropsResponseDataT = {
    crops: FarmSeasonCropT[];
    fields: FarmSeasonFieldT[];
};

export type FarmSeasonCropsPostCallBackT = (
    dispatch: Dispatch<PostFarmSeasonCropsDispatchTypesT>,
) => Promise<PostFarmSeasonCropsResponseErrorT>;

export type PostFarmSeasonCropsResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PostFarmSeasonCropsLoadingT = {
    type: typeof POST_FARM_SEASON_CROPS_LOADING;
};

export type PostFarmSeasonCropsFailedT = {
    type: typeof POST_FARM_SEASON_CROPS_FAILED;
    error: PostFarmSeasonCropsResponseErrorT;
};

export type PostFarmSeasonCropsSuccessT = {
    type: typeof POST_FARM_SEASON_CROPS_SUCCESS;
    payload: PostFarmSeasonCropsResponseDataT;
};

export type PostFarmSeasonCropsDispatchTypesT =
    | PostFarmSeasonCropsLoadingT
    | PostFarmSeasonCropsFailedT
    | PostFarmSeasonCropsSuccessT;
