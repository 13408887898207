import farmSeasonUpdateAction from '@actions/FarmSeasonActions/FarmSeasonUpdate';
import { StripeStatusUrlT } from '@routers/SubscribePageRouter/SubscribePageRouterTypes';
import { AppStateT } from '@store/store';
import useAppDispatch from '@hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAppNavigate from '@hooks/useAppNavigate';
import { PaymentStatusT } from '@components/PaymentStatusIcon/PaymentStatusIconTypes';
import selectIsProductPlanActive from '@store/selectors/selectIsProductPlanActive';
import getLatestProductPlanStatus from '@actions/ProductPlanActions/getLatestProductPlanStatus';
import selectCurrentSeason from '@/store/selectors/selectCurrentSeason';

const usePaymentDoneLogic = () => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { t } = useTranslation();

    const season = useSelector(selectCurrentSeason());

    const { stripeStatus } = useParams<StripeStatusUrlT>();

    // Context
    const farmId = useSelector((state: AppStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason?.id);
    // Statics
    const activeSeasonsList = useSelector(
        (state: AppStateT) =>
            state.static.seasons?.filter(
                (s) => s.active && s.country_group_id === state.farm.currentFarm?.country.country_group_id,
            ) || [],
    );

    // Store
    const isProductPlanActive = useSelector(selectIsProductPlanActive());
    const nextButtonLoading = useSelector((state: AppStateT) => state.farmSeason?.updateLoading);
    const hasServerError = useSelector((state: AppStateT) => !!state.farmSeason?.updateError);

    // Component states
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatusT | undefined>('saving');

    // fetch BE every 3s to know when paiment has been registered
    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;

        if (stripeStatus === 'success' && !isProductPlanActive && farmId) {
            interval = setInterval(() => {
                dispatch(getLatestProductPlanStatus({ farmId }));
            }, 3000);

            return () => clearInterval(interval);
        }

        if (isProductPlanActive) {
            clearInterval(interval);
        }

        return undefined;
    }, [isProductPlanActive]);

    // Manage payment status: saving -> canceled | success | done
    useEffect(() => {
        if (isProductPlanActive || stripeStatus === 'canceled') {
            setPaymentStatus(stripeStatus);
        }
    }, [isProductPlanActive]);

    const handleNextClicked = async () => {
        if (stripeStatus === 'canceled') {
            navigate('/subscribe/payment');
        } else {
            if (!farmId || !farmSeasonId || !season?.id) {
                throw Error('farmId, farmSeasonId, seasonId are required to call "farmSeasonUpdateAction"');
            }

            // create farmSeason
            const success = await dispatch(farmSeasonUpdateAction(farmId, farmSeasonId, { season_id: season?.id }));

            if (success) {
                window.location.replace(process.env.REACT_APP_V3_BASE_URL);
            }
        }
    };

    /* ----------------------------- textes ----------------------------- */
    const text: Record<PaymentStatusT, string> = {
        canceled: t('pages.subscription.payment-done.text-canceled'),
        done: t('pages.subscription.payment-done.blocking-text'),
        success: t('pages.subscription.payment-done.blocking-text'),
        saving: t('pages.subscription.payment-done.text-in-progress'),
    };
    const mainButtonText: Record<PaymentStatusT, string> = {
        canceled: t('constants.try-again'),
        done: t('pages.subscription.payment-done.button'),
        success: t('pages.subscription.payment-done.button'),
        saving: t('pages.subscription.payment-done.button'), // same text than 'done' as it's disabled
    };
    /* -------------------------------------------------------------------------- */

    return {
        handleNextClicked,
        nextButtonLoading,
        hasServerError,
        paymentStatus,
        text,
        mainButtonText,
    };
};

export default usePaymentDoneLogic;
