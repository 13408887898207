import selectSeasonProductPlan from '@/store/selectors/selectProductPlan';
import { AppStateT } from '@/store/store';
import { useSelector } from 'react-redux';

export const useIsSeasonClosed = () => {
    const openedCountryCodes = ['GB'];
    const isFromPartner = useSelector((state: AppStateT) => !!state.auth.user?.user_invitation?.partner_id);
    const countryCode = useSelector((state: AppStateT) => state.farm.currentFarm?.country?.iso_code) ?? '';
    const seasonProductPlan = useSelector(selectSeasonProductPlan());
    const isDeferred = seasonProductPlan?.status === 'active' && seasonProductPlan?.deferred_payment_start_date;

    return !(isFromPartner || openedCountryCodes.includes(countryCode) || isDeferred);
};
