import { AppStateT } from '../store';

const selectCurrencySymbol = () => (state: AppStateT) => {
    return state.subscription.subscriptionPaymentInfo?.country_id === 7 ||
        state.auth.user?.ip_country === 'gb' ||
        navigator.language === 'en-GB'
        ? '£'
        : '€';
};

export default selectCurrencySymbol;
