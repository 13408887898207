import { useTranslation } from 'react-i18next';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';
import ErrorMessageView from '@components/ErrorMessage/ErrorMessageView';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';

import CardFlow from '../components/CardFlow/CardFlow';
import EndWrapper from '../components/EndWrapper/EndWrapper';

import useSelectSeasonLogic from './SelectSeason.logic';
import useSelectSeasonStyle from './SelectSeason.style';
import { useSelector } from 'react-redux';
import { AppStateT } from '@/store/store';

const SelectSeason = (): JSX.Element => {
    const isArgentinianFarm = useSelector((state: AppStateT) => state.farm.currentFarm?.country.iso_code === 'AR');
    const justifyContent = isArgentinianFarm ? 'center' : 'start';
    const { t } = useTranslation();
    const { hasServerError, handleNextClicked, nextButtonLoading } = useSelectSeasonLogic();
    const { classes } = useSelectSeasonStyle({ justifyContent });

    return (
        <CardFlow progress={100}>
            <div className={classes.flexContaier}>
                <div className={classes.PaymentStatusIcon}>
                    <GridCheckCircleIcon className={classes.iconDropShadow} color="inherit" />
                </div>

                <EndWrapper
                    handleMainButton={handleNextClicked}
                    mainButtonLoading={nextButtonLoading}
                    mainButtonText={t('pages.subscribe.select-season.button')}
                    text={t('pages.subscribe.select-season.text')}
                    title={t('pages.subscribe.select-season.title')}
                    titleColor="primary"
                />
                {hasServerError && <ErrorMessageView message={t('constants.server-error')} />}
            </div>
        </CardFlow>
    );
};

export default SelectSeason;
