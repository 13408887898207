import useInitialFetch from '@hooks/useInitialFetch';
import FarmSeasonApiService from '@services/farmSeasonApiService/farmSeasonApiService';
import { ApprovalsDataT } from '@services/farmSeasonApiService/FarmSeasonApiServiceTypes';
import { AppStateT } from '@store/store';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';
import productPlanIsPayable from '@/routers/SubscribePageRouter/productPlanIsPayable.guard';
import { isNotFromArgentina } from '@/services/guardsService/guardsService';
import getLatestProductPlanStatus from '@/store/actions/ProductPlanActions/getLatestProductPlanStatus';
import useAppDispatch from '@hooks/useAppDispatch';
import { SIGN_TAC } from '@/store/actions/SubscriptionActions/SubscriptionSaveFarmTypes';

const useContractStepLogic = () => {
    const navigate = useAppNavigate();
    const farmId = useSelector((state: AppStateT) => state.farm.currentFarm?.id);
    const countryIso = useSelector((state: AppStateT) => state.farm.currentFarm?.country.iso_code);
    const isArgentinianFarm = countryIso === 'AR';
    const isFrenchFarm = countryIso === 'FR';
    const isBelgianFarm = countryIso === 'BE';
    const farmSeason = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason);
    const dispatch = useAppDispatch();

    const initialApprovals = {
        exclude_sunflower: false,
        land_owner_approval: false,
        approval: false,
        exclude_rapeseed: false,
    };

    if (!farmId || !farmSeason?.id) {
        throw Error('farmId and farmSeason.id are required to call "FarmSeasonApiService.getApprovals"');
    }

    dispatch(getLatestProductPlanStatus({ farmId }));

    const {
        isLoading: loadingGetApprovals,
        data: approvals,
        setData: setApprovals,
    } = useInitialFetch(async () => await FarmSeasonApiService.getApprovals(farmId, farmSeason.id), initialApprovals);
    const [loadingSave, setLoadingSave] = useState(false);

    const handleSaveTermsAndConditions = async () => {
        setLoadingSave(true);
        try {
            await FarmSeasonApiService.postApprovals(farmId, farmSeason?.id, {
                ...initialApprovals,
                ...approvals,
            });
            // navigate('/subscribe/payment');
            const productPlanIsPayableGuard = productPlanIsPayable();
            const isNotFromArgentinaGuard = isNotFromArgentina();

            isNotFromArgentinaGuard.passed && productPlanIsPayableGuard.passed
                ? navigate('/subscribe/payment')
                : navigate('/subscribe/select-season');
        } finally {
            dispatch({ type: SIGN_TAC });
            setLoadingSave(false);
        }
    };

    const setApproval = (check: keyof ApprovalsDataT, value: boolean) => {
        setApprovals({ ...initialApprovals, ...approvals, [check]: value });
    };

    return {
        handleSaveTermsAndConditions,
        loadingSave,
        loadingGetApprovals,
        approvals,
        setApproval,
        countryIso,
        isArgentinianFarm,
        isFrenchFarm,
        isBelgianFarm,
    };
};

export default useContractStepLogic;
