import { postFarmSeasonLivestocksSubscription } from '@actions/FarmSeasonLivestockActions/PostFarmSeasonLivestocksSubscription';
import { AppStateT } from '@store/store';
import useAppDispatch from '@hooks/useAppDispatch';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import useAppNavigate from '@hooks/useAppNavigate';
import { LivestockDataT } from '@pages/HomePage/Installation/InstallationTasks/LivestocksTask/LivestocksTaskTypes';
import emptyFieldValuesToNull from '@/utils/emptyFieldValuesToNull';
import { NumberFieldValue } from '@/utils/NumbersToFieldValues';

import { PostLivestocksDataT } from './LivestockStepTypes';
import LivestockStepView from './LivestockStepView';
import getFarmSeasonLivestock from '@actions/FarmSeasonLivestockActions/GetFarmSeasonLivestocks';
import getLatestProductPlanStatus from '@/store/actions/ProductPlanActions/getLatestProductPlanStatus';

const LivestockStep = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const farmId = useSelector((state: AppStateT) => state.farm.currentFarm?.id);
    const farmSeason = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason);
    const livestockList = useSelector((state: AppStateT) => state.farmSeasonLivestock?.livestockList);
    const livestockState = useSelector((state: AppStateT) => state.farmSeasonLivestock);
    const [serverError, setServerError] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);

    if (farmId) {
        dispatch(getLatestProductPlanStatus({ farmId }));
    }

    useEffect(() => {
        // load livestock
        if (!livestockState.loading && !livestockState.loaded) {
            if (farmId && farmSeason?.id) {
                dispatch(getFarmSeasonLivestock(farmId, farmSeason.id));
            }
        }
    }, []);

    const validationSchema = yup.object().shape({
        livestocks: yup
            .array()
            .of(
                yup.object().shape({
                    livestock_id: yup.string().required(t('pages.installationtasks.livestock-task.type-required')),
                    quantity: yup
                        .string()
                        .test(
                            'Must have more than 0',
                            t('pages.installationtasks.livestock-task.quantity-needs-to-be-higher-than-0'),
                            (value) => parseInt(value ?? '', 10) > 0,
                        ),
                }),
            )
            .min(1, t('pages.installationtasks.livestock-task.must-have-livestock'))
            .required(t('pages.installationtasks.livestock-task.must-have-livestock')),
    });

    const formik = useFormik({
        initialValues: {
            livestocks:
                livestockList?.length && livestockList.length > 0
                    ? livestockList?.map<LivestockDataT>((livestock) => ({
                          livestock_id: livestock.livestock_id,
                          quantity: livestock.quantity || ('' as NumberFieldValue),
                      }))
                    : [{ quantity: '', livestock_id: '' } as LivestockDataT],
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async (values: PostLivestocksDataT) => {
            if (!farmId || !farmSeason?.id) {
                throw Error('farmId and farmSeason.id are required for "postFarmSeasonLivestocksSubscription"');
            }
            setServerError('');
            setSubmitLoading(true);

            const { error, eligibility_status } = (await dispatch(
                postFarmSeasonLivestocksSubscription(farmId, farmSeason?.id, {
                    livestocks: values.livestocks.map((livestock) => emptyFieldValuesToNull(livestock)),
                }),
            )) as {
                error?: unknown;
                eligibility_status?: string;
            };

            if (error) {
                setServerError(t('constants.server-error'));
                setSubmitLoading(false);
            } else {
                navigate(`/subscribe/eligibility/${eligibility_status}`);
            }
        },
    });

    return (
        <LivestockStepView
            formik={formik}
            serverError={serverError}
            submitLoading={submitLoading}
            getLivestockLoading={livestockState.loading}
        />
    );
};

export default LivestockStep;
