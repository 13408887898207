import login from '@actions/AuthActions/LoginActions';
import useAppDispatch from '@hooks/useAppDispatch';
import { AppStateT } from '@store/store';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { acceptedThroughEmailInvitation } from '@api/invitation.slice';
import { useSearchParams } from 'react-router-dom';
import LoginPageView from './LoginPageView';

const LoginPage = (): JSX.Element => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const loading = useSelector((state: AppStateT) => state.auth.loading);
    const auth0Loading = useSelector((state: AppStateT) => state.auth.auth0Loading);
    const [remember, setRemember] = useState(false);

    const { t } = useTranslation();
    const validationSchema = yup.object({
        email: yup.string().email(t('validation.invalid-email')).required(t('validation.no-email-provided')),
        password: yup.string().required(t('validation.no-password-provided')),
    });

    const activeUserError = useSelector((state: AppStateT) =>
        state.auth.error?.list?.find((err) => err === 'user-not-active') ? t('errors.user-not-active') : null,
    );

    const allowedUserError = useSelector((state: AppStateT) =>
        state.auth.error?.list?.find((err) => err === 'user-not-allowed') ? t('errors.user-not-allowed') : null,
    );

    useEffect(() => {
        dispatch(acceptedThroughEmailInvitation(searchParams.has('email')));
    }, []);

    const formik = useFormik({
        initialValues: {
            email: searchParams.get('email') ?? '',
            password: '',
        },
        validationSchema,
        enableReinitialize: true,
        onSubmit: async ({ email, password }, { setSubmitting, setErrors }) => {
            const errors: { email?: string; password?: string } = {};

            if (password.length >= 6) {
                const response = await dispatch(login({ email: email.toLowerCase(), password }, remember));

                if (response.list?.length > 0) {
                    if (response.list?.includes('email')) {
                        errors.email = t('validation.invalid-email-or-password');
                    }

                    if (response.list?.includes('password')) {
                        errors.password = t('validation.invalid-email-or-password');
                    }
                }
            }

            if (password.length < 6) {
                errors.email = t('validation.invalid-email-or-password');
                errors.password = t('validation.invalid-email-or-password');
            }

            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                setSubmitting(false);
            }
        },
    });

    return (
        <LoginPageView
            userError={activeUserError || allowedUserError}
            auth0Loading={auth0Loading}
            formik={formik}
            loading={loading}
            remember={remember}
            setRemember={setRemember}
            t={t}
        />
    );
};

export default LoginPage;
