import { makeStyles } from 'tss-react/mui';

const useSignUpPageStyle = makeStyles()((theme) => ({
    checkbox: {
        marginTop: 0,
        '& > div': {
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginBottom: 0,
        },
    },
    loginLink: {
        textDecoration: 'underline',
    },

    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },

    // TODO this button should exist with the theme or should be a component.
    buttonLight: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 8px 10px 1px rgb(0 0 0 / 5%), 0px 3px 14px 2px rgb(0 0 0 / 5%);',
        '&:hover': {
            backgroundColor: theme.palette.grey[100],
            boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%);',
        },
    },

    modalHolder: {
        zIndex: 999999,
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.3)',
    },

    sideZoneImage: {
        backgroundImage: "url('/assets/images/landscape-fields-original-min.jpg')",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        borderRadius: '15px',
        [theme.breakpoints.up('md')]: {
            borderRadius: '0px',
        },
        width: '100%',
        height: '100%',
    },

    joinText: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
    },
}));

export default useSignUpPageStyle;
