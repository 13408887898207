import FarmSeasonLivestocksApiService from '@services/farmSeasonLivestocksApiService/farmSeasonLivestocksApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    PostFarmSeasonLivestocksSubscriptionDataT,
    PostFarmSeasonLivestocksSubscriptionDispatchTypesT,
    PostFarmSeasonLivestocksSubscriptionResponseDataT,
    PostFarmSeasonLivestocksSubscriptionResponseErrorT,
    POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_FAILED,
    POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_LOADING,
    POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_SUCCESS,
} from './PostFarmSeasonLivestocksSubscriptionTypes';

export const postFarmSeasonLivestocksSubscription =
    (farmId: number, farmSeasonId: number, body: PostFarmSeasonLivestocksSubscriptionDataT) =>
    async (dispatch: Dispatch<PostFarmSeasonLivestocksSubscriptionDispatchTypesT>) => {
        try {
            dispatch({ type: POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_LOADING });
            const res: AxiosResponse<PostFarmSeasonLivestocksSubscriptionResponseDataT> =
                await FarmSeasonLivestocksApiService.postSaveLivestocksSubscription(farmId, farmSeasonId, body);

            dispatch({
                type: POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_SUCCESS,
                payload: res.data,
            });

            return { error: false, eligibility_status: res.data.eligibility_status };
        } catch (error) {
            dispatch({
                type: POST_FARM_SEASON_LIVESTOCKS_SUBSCRIPTION_FAILED,
                error: error as PostFarmSeasonLivestocksSubscriptionResponseErrorT,
            });

            return { error };
        }
    };

export const placeholder = '';
