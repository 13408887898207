import { makeStyles } from 'tss-react/mui';

const useLabelTextFieldStyle = makeStyles()((theme) => ({
    label: {
        marginBottom: '10px',
        textAlign: 'left',
    },
    textField: {
        '& .MuiInputAdornment-root p': {
            color: theme.palette.driftwood.light,
            fontSize: '13px',
            fontWeight: theme.typography.fontWeightBold,
        },
    },
}));

export default useLabelTextFieldStyle;
