import { useState } from 'react';
import { Box, Typography, FormHelperText, Modal, Button } from '@mui/material';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import LabelTextField from '@components/LabelTextField/LabelTextField';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import useSignUpPageStyle from '@pages/SignUpPage/SignUpPage.style';
import { Trans, useTranslation } from 'react-i18next';
import LayoutAuth from '@components/LayoutAuth/LayoutAuth';
import DividerText from '@components/DividerText/DividerText';
import useAppNavigate from '@hooks/useAppNavigate';
import LabelPasswordField from '@components/LabelPasswordField/LabelPasswordField';
import Auth0Buttons from '@components/Auth0Buttons/Auth0Buttons';
import ValidationField from '@components/ValidationField/ValidationField';

import { SignUpPageViewPropsT } from './SignUpPageTypes';
import TOSModalView from './TOSModal/TOSModalView';
import { useSearchParams } from 'react-router-dom';

const SignUpPageView = ({ formik, loading, auth0Loading, passwordRules }: SignUpPageViewPropsT): JSX.Element => {
    const [openModal, setOpenModal] = useState(false);
    const { classes } = useSignUpPageStyle();
    const navigate = useAppNavigate();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const canRegister = process.env.REACT_APP_REGISTER_ENABLED === 'true';

    const [showPasswordValidations, setShowPasswordValidations] = useState(false);

    return (
        <LayoutAuth
            form={
                <form id="signup-form" noValidate onSubmit={formik.handleSubmit}>
                    <LabelTextField
                        autoComplete="fname"
                        disabled={loading}
                        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                        helperText={formik.touched.first_name && formik.errors.first_name}
                        id="first_name"
                        label={t('pages.signup.first-name')}
                        name="first_name"
                        type="text"
                        value={formik.values.first_name}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={formik.handleChange}
                    />
                    <LabelTextField
                        autoComplete="lname"
                        disabled={loading}
                        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                        helperText={formik.touched.last_name && formik.errors.last_name}
                        id="last_name"
                        label={t('pages.signup.last-name')}
                        name="last_name"
                        type="text"
                        value={formik.values.last_name}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={formik.handleChange}
                    />
                    <LabelTextField
                        autoComplete="email"
                        disabled={loading || searchParams.get('email') !== null}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        id="email"
                        label={t('pages.signup.email-address')}
                        name="email"
                        type="email"
                        value={formik.values.email}
                        variant="outlined"
                        fullWidth
                        required
                        onChange={formik.handleChange}
                    />
                    <ValidationField open={showPasswordValidations} rules={passwordRules}>
                        <LabelPasswordField
                            autoComplete="current-password"
                            disabled={loading}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            id="password"
                            label={t('pages.signup.password')}
                            name="password"
                            value={formik.values.password}
                            variant="outlined"
                            fullWidth
                            required
                            onBlur={() => setShowPasswordValidations(false)}
                            onChange={formik.handleChange}
                            onFocus={() => setShowPasswordValidations(true)}
                        />
                    </ValidationField>
                    <LabelTextField
                        autoComplete="referral-code"
                        disabled={loading}
                        error={formik.touched.referral_code && Boolean(formik.errors.referral_code)}
                        helperText={formik.touched.referral_code && formik.errors.referral_code}
                        helpName="referral-code"
                        id="referral_code"
                        label={t('pages.signup.referral-code')}
                        name="referral_code"
                        type="text"
                        withHelp
                        value={formik.values.referral_code}
                        variant="outlined"
                        fullWidth
                        onChange={formik.handleChange}
                    />
                    <Box className={classes.checkbox} my={2} textAlign="left">
                        <LabelCheckboxView
                            disabled={loading}
                            label={
                                <Trans i18nKey="pages.signup.i-agree-to-the-terms-of-use-and-privacy-policy">
                                    I agree to the
                                    <span /* eslint-disable-line */
                                        style={{ textDecoration: 'underline' }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOpenModal(true);
                                        }}
                                    >
                                        terms of use and privacy policy
                                    </span>
                                    .
                                </Trans>
                            }
                            name="terms"
                            value={formik.values.terms}
                            onChange={formik.handleChange}
                        />
                        {formik.touched.terms && !!formik.errors.terms && (
                            <FormHelperText error>{formik.errors.terms}</FormHelperText>
                        )}
                    </Box>
                    <Box my={4}>
                        <LoadingButton
                            id="submit-button"
                            loading={loading}
                            text={t('constants.signup')}
                            type="submit"
                            variant="contained"
                            disableElevation
                            fullWidth
                            disabled={!canRegister}
                        />
                    </Box>
                    <DividerText className={classes.divider} text={t('pages.signup.login-question')} />
                    <Button
                        className={classes.buttonLight}
                        style={{ width: '100%' }}
                        onClick={() => navigate('/login')}
                    >
                        {t('constants.login')}
                    </Button>
                    {!searchParams.get('token') && (
                        <>
                            <DividerText className={classes.divider} text={t('pages.signup.auth0-divider')} />
                            <Auth0Buttons auth0Loading={auth0Loading} />
                        </>
                    )}
                    <Modal className={classes.modalHolder} open={openModal} onClose={() => setOpenModal(false)}>
                        <div>
                            <TOSModalView onClose={() => setOpenModal(false)} />
                        </div>
                    </Modal>
                </form>
            }
            sideZone={<div className={classes.sideZoneImage}></div>}
            title={t('pages.signup.create-account-title')}
            underFormZone={
                <Typography className={classes.joinText} color="secondary" variant="h4">
                    {t('pages.signup.join-farmers-text')}
                </Typography>
            }
        />
    );
};

export default SignUpPageView;
