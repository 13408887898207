import AcceptInviteCard from './AcceptInviteCard/AcceptInviteCard';
import userAcceptInvitePage from './AcceptInvitePage.style';

function AcceptInvitePage() {
    const { classes } = userAcceptInvitePage();

    return (
        <div className={classes.container}>
            <AcceptInviteCard />
        </div>
    );
}

export default AcceptInvitePage;
