import { Paper, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import LoadingButton from '@components/LoadingButton/LoadingButton';

import useSelectionCardViewStyle from './SelectionCardView.style';
import { SelectionCardViewPropsT } from './SelectionCardViewTypes';
import LoadingSection from '../LoadingSection/LoadingSection';

const SelectionCardView = ({
    image,
    inverseColor,
    title,
    description,
    buttonLabel,
    onClick,
    withHeadline,
    headline,
    loading,
    disabled,
    buttonTooltipSlug,
    initialLoading,
    hideButton,
}: SelectionCardViewPropsT): JSX.Element => {
    const { classes } = useSelectionCardViewStyle({ inverseColor, headlineType: headline?.type });

    return (
        <Paper className={classes.container} elevation={0} variant="elevation">
            <LoadingSection loading={initialLoading} transparentLoadingSection>
                <div className={classes.wrapper}>
                    <img alt="graph" className={classes.image} height="120px" src={image} width="120px" />
                </div>
                <div className={classes.wrapper}>
                    <Typography className={classes.title} variant="h4">
                        {title}
                    </Typography>
                </div>
                <div className={classes.wrapper}>
                    <Typography className={classes.description} variant="body1">
                        {description}
                    </Typography>
                </div>
                {!hideButton && (
                    <div className={`${classes.buttonContainer} ${classes.wrapper}`}>
                        <LoadingButton
                            className={classes.button}
                            disabled={disabled}
                            loading={loading}
                            text={buttonLabel}
                            tooltipSlug={buttonTooltipSlug}
                            onClick={onClick}
                        />
                    </div>
                )}
            </LoadingSection>
            {withHeadline && (
                <div className={classes.headline}>
                    <Typography className={classes.headlineText}>
                        <Trans i18nKey={headline?.i18nKey}>
                            <a
                                className={classes.link}
                                href="#/"
                                id={headline?.i18nKey.split('.').join('')}
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            ></a>
                        </Trans>
                    </Typography>
                </div>
            )}
        </Paper>
    );
};

export default SelectionCardView;
