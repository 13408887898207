import { AxiosResponse } from 'axios';
import request from '@services/apiService/apiService';
import { SubscriptionSaveFarmDataT } from '@actions/SubscriptionActions/SubscriptionSaveFarmTypes';
import {
    SubscriptionSaveProductPlanDataT,
    SubscriptionSaveProductPlanSuccessDataT,
} from '@actions/SubscriptionActions/SubscriptionSaveProductPlanTypes';

class SubscriptionApiService {
    public static saveFarm = async (subscriptionSaveData: SubscriptionSaveFarmDataT): Promise<AxiosResponse> =>
        request({
            url: '/subscription/save-farm',
            method: 'POST',
            data: subscriptionSaveData,
        });

    public static updateFarmSeasonProductPlan = async ({
        farmId,
        farmSeasonId,
        productPlanId,
        farmSeasonProductPlanId,
    }: SubscriptionSaveProductPlanDataT): Promise<AxiosResponse<SubscriptionSaveProductPlanSuccessDataT>> => {
        return request({
            url: `/subscription/farm/${farmId}/season/${farmSeasonId}/update-product-plan/${farmSeasonProductPlanId}`,
            method: 'PATCH',
            data: { product_plan_id: productPlanId },
        });
    };
}

export default SubscriptionApiService;
