import { makeStyles } from 'tss-react/mui';

const useFarmStepViewStyle = makeStyles()(() => ({
    gridContainer: {
        width: '100%',
    },
    align: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    phoneDropdown: {
        height: '400px',
        bottom: 0,
    },
}));

export default useFarmStepViewStyle;
