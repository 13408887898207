import { makeStyles } from 'tss-react/mui';
import theme from '@theme/theme';

export const useInvitationFromPartnerStyles = makeStyles()(() => ({
    container: {
        padding: 0,
        height: '100%',
        minHeight: 'calc(100vh - 48px)',
        position: 'relative',
        background: 'rgba(226, 208, 182, 0.15)',
    },
    background: {
        position: 'absolute',
        bottom: 0,
        width: '100vw',
        maxWidth: '1920px',
    },
    darkBackground: {
        height: '20vh',
        width: '100%',
        background: 'rgba(230, 208, 182, 0.30)',
    },
    coloredContainer: {
        backgroundColor: theme.palette.secondaryDrySage.main,
        height: '35vh',
        width: '100%',
        overflow: 'hidden',
    },
    content: {
        padding: '0 15px',
    },
    secondaryAction: {
        background: theme.palette.grey['200'],
        color: theme.palette.primary.main,
        '&:hover': {
            background: theme.palette.grey['300'],
        },
    },
}));
