import { Box, Divider, Grid, Typography } from '@mui/material';
import selectCurrencySymbol from '@store/selectors/selectCurrencySymbol';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CardFlow from '../components/CardFlow/CardFlow';
import ProgressWrapper from '../components/ProgressWrapper/ProgressWrapper';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import LabelTextField from '@/components/LabelTextField/LabelTextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { AppStateT } from '@/store/store';
import updateAmbassador from '@actions/AuthActions/UserAddAmbassador';
import useAppDispatch from '@hooks/useAppDispatch';
import { useEffect } from 'react';
import PaymentApiService from '@/services/paymentApiService/paymentApiService';

export default () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const [loading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();

    const farmId = useSelector((state: AppStateT) => state.farm.currentFarm?.id);
    const currency = useSelector(selectCurrencySymbol());
    const farmSeasonId = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason?.id);

    const wrongAmbassadorCodeError = useSelector((state: AppStateT) =>
        state.auth.error?.list?.find((err) => err === 'Ambassador not found')
            ? t('pages.signup.referral_code_not_found')
            : undefined,
    );
    const validationSchema = yup.object({ referral_code: yup.string() });

    const savedReferalCode = useSelector((state: AppStateT) => state.auth.ambassadorReferralCode);

    const disabledReferralCodeInput = !!savedReferalCode;

    const formik = useFormik({
        initialValues: {
            referral_code: savedReferalCode || '',
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: async ({ referral_code }, { setSubmitting }) => {
            setIsLoading(true);
            const response = await dispatch(updateAmbassador(referral_code));
            setIsLoading(false);
            if (response?.success) {
                redirectToPayment();
            }
            setSubmitting(false);
        },
    });

    const paymentInfo = useSelector((state: AppStateT) => state.subscription.subscriptionPaymentInfo);

    const redirectToPayment = async () => {
        setIsLoading(true);
        const response = await PaymentApiService.postPaymentDetail(
            farmId,
            farmSeasonId,
            paymentInfo,
            'first',
            i18n.language,
        );
        setIsLoading(false);

        return window.location.replace(response.data.redirectUrl);
    };

    useEffect(() => {
        if (wrongAmbassadorCodeError) {
            formik.setErrors({ ...formik.errors, referral_code: wrongAmbassadorCodeError });
        }
    }, [wrongAmbassadorCodeError]);

    return (
        <CardFlow progress={60}>
            <ProgressWrapper
                title={t('pages.subscribe.product-plan.title', { currency })}
                subTitle={t('pages.subscribe.product-plan.sub-title', { currency })}
                nextButtonText={t('pages.subscribe.product-plan.next')}
                handleNextButton={() => formik.handleSubmit()}
                nextButtonLoading={loading}
                showBackLink={
                    <form id="referral-code-form" noValidate onSubmit={formik.handleSubmit}>
                        <LabelTextField
                            sx={{ paddingRight: '80px' }}
                            autoComplete="referral-code"
                            error={formik.touched.referral_code && Boolean(formik.errors.referral_code)}
                            helperText={formik.touched.referral_code && formik.errors.referral_code}
                            helpName="referral-code"
                            id="referral_code"
                            label={t('pages.signup.referral-code')}
                            name="referral_code"
                            type="text"
                            withHelp
                            value={formik.values.referral_code}
                            variant="outlined"
                            fullWidth
                            onChange={formik.handleChange}
                            disabled={disabledReferralCodeInput}
                        />
                    </form>
                }
            >
                <>
                    <Box
                        color="primary"
                        sx={{
                            borderRadius: '10px',
                            backgroundColor: '#F2F2F2',
                            padding: '10px 31px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '18px',
                        }}
                    >
                        <Typography variant="body1" sx={{ fontSize: '18px', fontWeight: '700' }}>
                            {t('pages.subscribe.product-plan.box.title')}
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '18px', fontWeight: '700' }}>
                            980 {currency}
                            <span style={{ fontWeight: '400' }}> {t('pages.subscribe.product-plan.box.label')} </span>
                        </Typography>
                    </Box>
                    <Divider color="#F2F2F2" sx={{ marginBottom: '22px' }} />
                    <Grid container spacing={4} sx={{ mb: 4 }}>
                        <Grid item display="flex">
                            <CheckBoxOutlinedIcon sx={{ mr: 2.5 }} color="primary" />
                            <Box>
                                <Typography variant="body1" sx={{ fontSize: '18px', fontWeight: '700' }}>
                                    {t('pages.subscribe.product-plan.bullet-points.carbon.title')}
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                    {currency === '£'
                                        ? t('pages.subscribe.product-plan.bullet-points.carbon.label-uk')
                                        : t('pages.subscribe.product-plan.bullet-points.carbon.label')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item display="flex">
                            <CheckBoxOutlinedIcon sx={{ mr: 2.5 }} color="primary" />
                            <Box>
                                <Typography variant="body1" sx={{ fontSize: '18px', fontWeight: '700' }}>
                                    {t('pages.subscribe.product-plan.bullet-points.flexibility.title')}
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                    {t('pages.subscribe.product-plan.bullet-points.flexibility.label')}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item display="flex">
                            <CheckBoxOutlinedIcon sx={{ mr: 2.5 }} color="primary" />
                            <Box>
                                <Typography variant="body1" sx={{ fontSize: '18px', fontWeight: '700' }}>
                                    {t('pages.subscribe.product-plan.bullet-points.advices.title')}
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: '400' }}>
                                    {t('pages.subscribe.product-plan.bullet-points.advices.label')}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            </ProgressWrapper>
        </CardFlow>
    );
};
