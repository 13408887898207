import { useModal } from '@soil-capital/ui-kit';
import { FieldNameAreaDataT } from '@components/FieldNameAreaModal/FieldNameAreaModalTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppNavigate from '@hooks/useAppNavigate';
import useTotalAreaCheckModals from '@hooks/useTotalAreaCheckModals/useTotalAreaCheckModals';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import SegmentService from '@services/segmentService/segmentService';
import { postFarmSeasonFields } from '@store/actions/FarmSeasonFieldActions/PostFarmSeasonFields';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import { useSelector } from 'react-redux';
import { FieldsTaskLogicPropsT } from './FieldsTaskTypes';
import { AppStateT } from '@store/store';
import { useEffect, useState } from 'react';
import fieldEntity from '@entities/fieldEntity';
import cropEntity from '@entities/cropEntity';
import useOperationDone from '@/hooks/useOperationDone';
import { useIsCFTCarbonModel } from '@/hooks/useIsCFTCarbonModel';

const useFieldsTaskLogic = ({ fieldNameAreaModalController }: FieldsTaskLogicPropsT) => {
    const dispatch = useAppDispatch();
    const farmId = useSelector((state: HomePageStateT) => state.farm.currentFarm.id);
    const farmSeasonId = useSelector((state: HomePageStateT) => state.farmSeason.currentFarmSeason.id);
    const fieldLoading = useSelector((state: HomePageStateT) => state.farmSeasonField.loading);
    const modalRestartController = useModal<void>();
    const deleteCropModal = useModal();
    const controllerWarningAnyOperationCreated = useModal();
    const isCFT = useIsCFTCarbonModel();
    const isBaselineSeason = useSelector(selectIsBaselineSeason());
    const { TotalAreaIncreaseModal, checkTotalAreaWithPreviousSeason, TotalAreaDecreaseModal, checkTotalAreaLoading } =
        useTotalAreaCheckModals({
            farmId,
            farmSeasonId,
        });
    const isArgentinianFarm = useSelector((state: AppStateT) => state.farm.currentFarm?.country.iso_code === 'AR');

    const { fieldState } = fieldEntity.useState({ farmSeasonId });
    const [updateField] = fieldEntity.useUpdate();
    const [deleteField, { isLoading: deleteFieldLoading }] = fieldEntity.useDelete();
    const { cropState } = cropEntity.useState({ farmSeasonId });
    const { getCropsCountForField } = cropState.useGetCropsCountForField();
    const { getHasV3OperationsCreated } = useOperationDone();

    const navigate = useAppNavigate();
    const [confirmFieldsTaskAttempted, setConfirmFieldsTaskAttempted] = useState(false);

    /* - Still compute tasks in background if the user leave without "continue" - */
    const [continueClicked, setContinueClicked] = useState(false);
    useEffect(() => {
        return () => {
            if (continueClicked) {
                return;
            }
            dispatch(postFarmSeasonFields(farmId, farmSeasonId, { fields: false }));
        };
    }, []);

    const onConfirmFieldsTask = async () => {
        setConfirmFieldsTaskAttempted(true);
        if (!fieldState.list.length) {
            return;
        }
        setContinueClicked(true);

        await dispatch(postFarmSeasonFields(farmId, farmSeasonId, { fields: false }));

        if (!isBaselineSeason) {
            const { userStopped } = await checkTotalAreaWithPreviousSeason();
            if (userStopped) {
                return;
            }
        }

        navigate('..');
    };

    if (fieldState.list.find((f) => !f?.original_area)) {
        throw Error("a field in fields task list don't have original_area");
    }

    const handleFieldDelete = async (polygonId: number) => {
        const hasAnyOperationCreated = getHasV3OperationsCreated({ farmSeasonFieldId: polygonId });
        const hasLinkedCrop = getCropsCountForField({ fieldId: polygonId });
        if (!isCFT && hasAnyOperationCreated) {
            controllerWarningAnyOperationCreated.openModal();
        } else if (hasLinkedCrop) {
            // PolygonId = FieldId
            let resolvePromise: ((v: void) => void) | null = null;
            const promise = new Promise<void>((res) => (resolvePromise = res));
            deleteCropModal.openModal(
                () => {
                    deleteField({ farmSeasonId, id: polygonId }).then(() => resolvePromise?.());
                },
                () => {
                    resolvePromise?.();
                },
            );
            await promise;
        } else {
            await deleteField({ farmSeasonId, id: polygonId });
        }
    };

    const handleRestartEncodings = () => {
        modalRestartController.openModal(async () => {
            await dispatch(postFarmSeasonFields(farmId, farmSeasonId, { fields: [] }));
        });
    };

    const handleEditFieldNameArea = async (polygonId: number, props: FieldNameAreaDataT) => {
        SegmentService.fieldEditionClickedTrack({
            area_source: props.areaSource,
            name: props.name,
            original_area: props.originalArea,
            user_area: props.finalArea === props.originalArea ? null : props.finalArea,
        });

        const fieldData = await fieldNameAreaModalController.openEditFieldModal(props);
        if (!fieldData) {
            return;
        }
        const fieldFromState = fieldState.getById(polygonId);
        if (!fieldFromState) {
            return;
        }

        await updateField({
            farmSeasonId,
            id: polygonId,
            body: await fieldEntity.schema.validate({
                ...fieldFromState,
                name: fieldData.name,
                user_area: fieldData.userArea,
                polygon_geometry: fieldFromState.polygon.geometry,
            }),
        });
    };

    return {
        fieldLoading,
        onFieldDelete: handleFieldDelete,
        handleEditFieldNameArea,
        handleRestartEncodings,
        deleteCropModal,
        controllerWarningAnyOperationCreated,
        modalRestartController,
        TotalAreaIncreaseModal,
        TotalAreaDecreaseModal,
        checkTotalAreaLoading,
        fields: fieldState.list,
        isArgentinianFarm,
        onConfirmFieldsTask,
        confirmFieldsTaskAttempted,
        deleteFieldLoading,
    };
};

export default useFieldsTaskLogic;
