/*
 Partial used for "first payment done" in subscription + "second payment done" in validate farmer-data
 --------------------------------------------------------------------------
 */

import CardFlow from '@pages/SubscribePage/components/CardFlow/CardFlow';
import EndWrapper from '@pages/SubscribePage/components/EndWrapper/EndWrapper';

import { useTranslation } from 'react-i18next';
import ErrorMessageView from '@components/ErrorMessage/ErrorMessageView';

import usePaymentDoneCardStyle from './PaymentDoneCard.style';
import usePaymentDoneCardLogic from './PaymentDoneCard.logic';
import { PaymentDoneCardPropsT, PaymentDoneCardStateT } from './PaymentDoneCardTypes';
import PaymentStatusIcon from '../PaymentStatusIcon/PaymentStatusIcon';

const PaymentDoneCard = ({
    overrides,
    Children,
    hasServerError,
    paymentStatus,
}: PaymentDoneCardPropsT): JSX.Element => {
    const { classes } = usePaymentDoneCardStyle();
    const { t } = useTranslation();
    const { defaultTitle } = usePaymentDoneCardLogic();

    const sharedState: PaymentDoneCardStateT = { $paymentStatus: paymentStatus };

    return (
        <CardFlow progress={100} {...overrides.CardFlow}>
            <div className={classes.flexContainer}>
                <PaymentStatusIcon paymentStatus={paymentStatus} />
                <EndWrapper
                    mainButtonLoading={paymentStatus === 'saving'}
                    title={paymentStatus ? defaultTitle[paymentStatus] : ''}
                    titleColor="primary"
                    {...overrides.EndWrapper(sharedState)}
                >
                    {Children?.(sharedState)}
                </EndWrapper>
                {hasServerError && (
                    <ErrorMessageView message={t('constants.server-error')} {...overrides.ErrorMessageView} />
                )}
            </div>
        </CardFlow>
    );
};

export default PaymentDoneCard;
