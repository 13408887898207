import SubscriptionApiService from '@services/subscriptionApiService/subscriptionApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';

import {
    SubscriptionSaveFarmDataT,
    SubscriptionSaveFarmActionsT,
    SubscriptionFailedDataT,
    SubscriptionSaveFarmSuccessDataT,
    SUBSCRIPTION_SAVE_FARM_FAILED,
    SUBSCRIPTION_SAVE_FARM_LOADING,
    SUBSCRIPTION_SAVE_FARM_SUCCESS,
} from './SubscriptionSaveFarmTypes';

const subscriptionSaveFarm =
    (subscription: SubscriptionSaveFarmDataT) =>
    async (dispatch: Dispatch<SubscriptionSaveFarmActionsT>): Promise<SubscriptionFailedDataT> => {
        try {
            dispatch({ type: SUBSCRIPTION_SAVE_FARM_LOADING });

            const res: AxiosResponse<SubscriptionSaveFarmSuccessDataT> = await SubscriptionApiService.saveFarm(
                subscription,
            );

            dispatch({
                type: SUBSCRIPTION_SAVE_FARM_SUCCESS,
                payload: res.data,
            });

            return { error: false, data: res.data };
        } catch (error) {
            dispatch({
                type: SUBSCRIPTION_SAVE_FARM_FAILED,
                error: error as SubscriptionFailedDataT,
            });

            return { error };
        }
    };

export default subscriptionSaveFarm;
