import { makeStyles } from 'tss-react/mui';

const useContractStepStyle = makeStyles()((theme) => ({
    contractContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
    },
    contractText: {
        ...theme.styles.scrollSection,
        overflowY: 'auto',
        flexGrow: 1,
        marginBottom: '10px',
        paddingRight: '20px',
        '&::-webkit-scrollbar': {
            width: '10px',
        },
    },
    requiredStar: {
        color: 'red',
    },
    divider: {
        margin: '20px 0',
    },
    link: {
        cursor: 'pointer',
        color: '-webkit-link',
        textDecoration: 'underline',
    },
}));

export default useContractStepStyle;
