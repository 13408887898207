import { ModalBase } from '@soil-capital/ui-kit';
import ModalBaseLayout from '@layouts/ModalBaseLayout/ModalBaseLayout';
import useCertificatesGeneratedModalLogic from './CertificatesGeneratedModal.logic';
import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { GeneratedCertificateModalPropsT } from './CertificatesGeneratedModal.types';

const CertificatesGeneratedModal = ({ onReadCelebration }: GeneratedCertificateModalPropsT) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
        controller,
        handleRedirection,
        certificatesQty,
        firstPaymentAmount,
        firstPaymentCurrency,
        isLoading,
        hasIban,
        isError,
        onModalDisplayed,
    } = useCertificatesGeneratedModalLogic({ onReadCelebration });
    const { t } = useTranslation();

    // Avoid display the modal while the data is not ready
    if (isError || isLoading) {
        return null;
    }

    onModalDisplayed();

    return (
        <ModalBase width="560px" controller={controller} showCloseButton>
            <ModalBaseLayout
                title={{
                    text: (
                        <>
                            <span style={{ fontSize: '36px' }}>🎉</span>
                            <br />
                            <Trans i18nKey="modal.celebration.certificates-generated.title" />
                        </>
                    ),
                    alignment: 'center',
                }}
                confirmButton={{
                    text: hasIban
                        ? t('modal.celebration.certificates-generated.certificates-action')
                        : t('modal.celebration.certificates-generated.billing-action'),
                    onClick: handleRedirection,
                }}
            >
                <Typography variant="body2" align="center">
                    <Trans
                        i18nKey="modal.celebration.certificates-generated.new-description"
                        values={{
                            certificatesQty,
                        }}
                    />
                    <br /> <br />
                    <Trans i18nKey="modal.celebration.certificates-generated.explanation" />
                    <br /> <br />
                    {firstPaymentAmount && firstPaymentCurrency && (
                        <Trans
                            i18nKey="modal.celebration.certificates-generated.first-payment"
                            values={{ firstPaymentAmount, firstPaymentCurrency }}
                        />
                    )}

                </Typography>
                <br />
                {!hasIban && (
                    <Typography variant="body2" align="center">
                        <Trans i18nKey="modal.celebration.certificates-generated.iban-description" />
                    </Typography>
                )}
            </ModalBaseLayout>
        </ModalBase>
    );
};

export default CertificatesGeneratedModal;
