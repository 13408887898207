import { makeStyles } from 'tss-react/mui';

const useSelectionCardViewStyle = makeStyles<{ inverseColor?: boolean; headlineType?: 'success' | 'error' }>()(
    (theme, { inverseColor, headlineType }) => ({
        container: {
            minWidth: '330px',
            maxWidth: '600px',
            minHeight: '550px',
            boxShadow: '2px 2px 4px 1px rgba(216, 183, 149, 0.05)',
            borderRadius: '20px',
            background: inverseColor ? '#FFF' : theme.palette.primary.light,
            justifyContent: 'center',
            margin: 'auto',
            padding: '0px 60px 80px 60px',
            '@media (max-height: 700px)': {
                padding: '40px 60px 20px 60px',
            },
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            boxSizing: 'content-box',
        },
        wrapper: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        image: {
            marginTop: '20px',
        },
        title: {
            marginTop: '20px',
            textAlign: 'center',
            color: inverseColor ? theme.palette.primary.light : '#FFF',
            fontSize: '22px',
            '@media (max-height: 768px)': {
                marginTop: '10px',
            },
        },
        description: {
            marginTop: '20px',
            textAlign: 'center',
            color: inverseColor ? theme.palette.primary.light : '#FFF',
            fontSize: '14px',
            '@media (max-height: 768px)': {
                marginTop: '10px',
            },
        },
        buttonContainer: {
            flex: 1,
            alignItems: 'flex-end',
        },
        button: {
            marginTop: '40px',
            color: inverseColor ? '#FFF' : theme.palette.primary.light,
            background: inverseColor ? theme.palette.primary.light : '#FFF',
            '&:hover': {
                background: inverseColor ? theme.palette.primary.dark : theme.palette.grey[300],
            },
            '@media (max-height: 768px)': {
                marginTop: '20px',
            },
            lineHeight: '1.3em',
        },
        headline: {
            position: 'absolute',
            width: '660px',
            top: 0,
            left: 0,
            padding: '10px 30px',
            background:
                headlineType === 'success'
                    ? theme.palette.success.main
                    : headlineType === 'error'
                    ? theme.palette.error.main
                    : theme.palette.grey[100],
        },
        headlineText: {
            color: '#FFF',
            textAlign: 'center',
            '& a': {
                color: '#FFF',
            },
        },
        link: {
            textDecoration: 'underline',
            cursor: 'pointer',
        },
    }),
);

export default useSelectionCardViewStyle;
