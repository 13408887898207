import { useEffect, useState } from 'react';
import { checkEmailIsVerified } from '@services/guardsService/guardsService';
import { Alert } from '@mui/material';
import { t } from 'i18next';
import ButtonResendVerifyEmail from '@components/ButtonResendVerifyEmail/ButtonResendVerifyEmail';

// currently only used for email verification
const shouldShow = (): boolean => !checkEmailIsVerified().passed;
const message = () => (
    <>
        {t('pages.verify-email.top-banner')} <ButtonResendVerifyEmail buttonStyle="link" color="#fff" />
    </>
);

const useTopBanner = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(shouldShow());
    }, []);

    const topBanner = show && (
        <Alert severity="error" style={{ justifyContent: 'center' }} variant="filled" square>
            {message()}
        </Alert>
    );

    return {
        showTopBanner: show,
        topBanner,
    };
};

export default useTopBanner;
