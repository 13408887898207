import { Button, Grid, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import AddButtonView from '@components/AddButton/AddButtonView';
import { FieldArray, FormikProvider, getIn } from 'formik';
import { Prompt } from '@components/Prompt/Prompt';
import HelpButtonView from '@components/HelpButton/HelpButtonView';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import useCropListStepViewStyle from './CropListStepView.style';
import { CropListStepViewPropsT, FarmSeasonCropDataT } from './CropListStepTypes';
import CropItemView from './CropItem/CropItemView';
import { ModalWarning, useModal } from '@soil-capital/ui-kit';
import { useSelector } from 'react-redux';
import selectIsBaselineSeason from '@store/selectors/selectIsBaselineSeason';
import ModalUploadCapFile from '@components/ModalUploadCapFile/ModalUploadCapFile';
import { ModalUploadCapFileResponseT } from '@components/ModalUploadCapFile/ModalUploadCapFileTypes';
import { HomePageStateT } from '@pages/HomePage/types/HomePageStoreTypes';
import { geoJsonFeatureT } from '@components/Map/MapTypes';
import ShapeFileCropService from '@services/shapeFileCropApiService/shapeFileCrop.service';
import {
    FarmSeasonFieldCropDataT,
    FarmSeasonFieldT,
} from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import ShapeService from '@services/shapeService/shapeService';
import { FIELD_INTERSECT_PERCENTAGE_TO_LINK_CROP } from '@/constants/uploadCapForCrop';
import { Override } from '@/utils/Override';
import { useCanEditFarmSeason } from '@hooks/usePermissions';

const CropListStepView = ({
    formik,
    isSubmitted,
    t,
    modalDeleteCrop,
    onDeleteCrop,
    handleCropSuggestionOption,
    suggestCropsLoading,
    hasGotCropSuggestion,
    goToSelectFields,
    controllerWarningOperationDone,
    controllerWarningPermanentCrop,
    isPermanentCrop,
    isCFT,
}: CropListStepViewPropsT): JSX.Element => {
    const { classes } = useCropListStepViewStyle();
    const isBaselineSeason = useSelector(selectIsBaselineSeason());
    const farm = useSelector((store: HomePageStateT) => store.farm.currentFarm);
    const farmSeason = useSelector((store: HomePageStateT) => store.farmSeason.currentFarmSeason);

    const submitAttempted = formik.submitCount > 0;

    const modalUploadCapFile = useModal<ModalUploadCapFileResponseT>();
    const modalNoMatchingCropToImport = useModal();
    const modalCropsToImportAlreadyExist = useModal();
    const modalCropsImportedLinkedToFields = useModal();

    const { canEditFarmSeason } = useCanEditFarmSeason(farmSeason);

    const openUploadCapModal = async () => {
        modalUploadCapFile.openModal(async ({ uploadedFields }) => {
            const cropsToAdd: string[] = [];
            const uploadedFieldsWithCrop = uploadedFields.filter((uF) => !!uF.shapefileCrop) as Override<
                FarmSeasonFieldT,
                { id?: number; shapefileCrop: number | string }
            >[];
            let cropsAlreadyInForm = false;
            let cropsImportedLinkedToFields = false as boolean;

            if (uploadedFieldsWithCrop.length) {
                uploadedFieldsWithCrop.forEach((field) => {
                    if (cropsToAdd.indexOf(field.shapefileCrop as string) < 0) {
                        cropsToAdd.push(field.shapefileCrop as string);
                    }
                });
            }

            try {
                // convert shapeFileCrop ID to Crop ID
                const matchingCropsIdsArray = (
                    await ShapeFileCropService.convert(farm.country.iso_code as string, cropsToAdd)
                ).data?.filter((match) => !!match.cropId);

                const shapeFileToCropsMap: { [shapeFileCropId: string]: number } = matchingCropsIdsArray.reduce(
                    (mappedCrops, cropsIds) => ({ ...mappedCrops, [cropsIds.shapeFileCropId]: cropsIds.cropId }),
                    {},
                );

                // add new crops from CAP to the form (excluding duplicates)
                const newCrops: FarmSeasonCropDataT[] = [];
                matchingCropsIdsArray
                    // check if not aready in the form
                    .filter(
                        (cId) =>
                            !formik.values.crops.find((c) => c.crop.id === cId.cropId && c.is_accompanied === false),
                    )
                    .forEach((cId) => {
                        // avoid duplicate crop from cap. (different shapefileCrop can lead to same crop)
                        if (newCrops.find((newCrop) => newCrop.crop.id === cId.cropId)) {
                            return;
                        }
                        newCrops.push({
                            crop: {
                                id: cId.cropId,
                            },
                            yield: '',
                            is_accompanied: false,
                        });
                    });
                if (newCrops.length) {
                    formik.setFieldValue('crops', [...formik.values.crops.filter((c) => !!c.crop?.id), ...newCrops]);
                } else {
                    cropsAlreadyInForm = true;
                }

                // deep copy to avoid impacting redux state
                // TODO we could improve this with map instead of forEach
                const fieldsToSave = JSON.parse(JSON.stringify(formik.values.fields)) as FarmSeasonFieldT[];

                // add cap crops to current farmSeason fields
                uploadedFieldsWithCrop.forEach((uF) => {
                    fieldsToSave.forEach((field) => {
                        if (
                            ShapeService.intersectPolygonWithPercentage(
                                field.polygon,
                                uF.polygon as geoJsonFeatureT,
                                FIELD_INTERSECT_PERCENTAGE_TO_LINK_CROP,
                            )
                        ) {
                            const foundCropId = shapeFileToCropsMap[uF.shapefileCrop];

                            if (foundCropId && !field.field_crops?.length) {
                                cropsImportedLinkedToFields = true;
                                field.field_crops = [
                                    ...(field.field_crops as FarmSeasonFieldCropDataT[]),
                                    {
                                        order: (field.field_crops?.length as number) + 1,
                                        coupled_id: null,
                                        yield: null,
                                        farm_season_crop: {
                                            crop: {
                                                id: foundCropId,
                                            },
                                        },
                                    },
                                ];
                            }
                        }
                    });
                });
                formik.setFieldValue('fields', fieldsToSave);

                if (!matchingCropsIdsArray) {
                    modalNoMatchingCropToImport.openModal(); // no matching crop found from import
                } else if (cropsAlreadyInForm && !cropsImportedLinkedToFields) {
                    modalCropsToImportAlreadyExist.openModal(); // crops already exist
                } else if (cropsImportedLinkedToFields === true) {
                    modalCropsImportedLinkedToFields.openModal(); // some fields has been linked to the imported crop
                }
            } catch (e) {
                console.error(e);
                throw e;
            }
        });
    };

    return (
        <div className={classes.container}>
            <Prompt
                messageTitle={t('modal.navigation-prompt-if-unsaved.title')}
                messageText={t('modal.navigation-prompt-if-unsaved.text')}
                when={!isSubmitted && formik.dirty}
            />
            <Typography className={classes.title} color="secondary" variant="h4">
                <Trans i18nKey="pages.installationtasks.add-crop-task.crop-list-step.title">
                    Confirmez, ajoutez ou supprimez <br />
                    <span className={classes.darkerTitle}>des cultures</span>
                </Trans>
                <HelpButtonView id="pages.installationtasks.add-crop-task.crop-list-step.title" />
            </Typography>

            {!isBaselineSeason && (
                <>
                    {canEditFarmSeason.access && (
                        <LoadingButton
                            className={classes.button}
                            color="secondary"
                            fullWidth
                            onClick={openUploadCapModal}
                            loading={false}
                            startIcon={<FileUploadIcon />}
                            text={t('pages.installationtasks.add-field-task.encoding-option.cap-file')}
                        />
                    )}

                    {canEditFarmSeason.access &&
                        (hasGotCropSuggestion ? (
                            <Typography color="secondary" className={classes.cropsSuggestionDoneText}>
                                {t('pages.installationtasks.add-crop-task.crops-suggestion-done')}
                            </Typography>
                        ) : (
                            <LoadingButton
                                className={classes.button}
                                color="secondary"
                                fullWidth
                                onClick={handleCropSuggestionOption}
                                startIcon={<AutoFixHighIcon />}
                                loading={suggestCropsLoading}
                                text={t('pages.installationtasks.add-field-task.encoding-option.last-season')}
                            />
                        ))}
                </>
            )}

            <form id="crops-form" noValidate onSubmit={formik.handleSubmit}>
                <Grid spacing={2} style={{ justifyContent: 'flex-end' }} container>
                    <FormikProvider value={formik}>
                        <FieldArray
                            name="crops"
                            render={(arrayHelpers) => (
                                <>
                                    {formik.values.crops.map((crop, index) => {
                                        const key = `crops.${index}.key`;
                                        const cropIdName = `crops.${index}.crop.id`;
                                        const errorCropId = getIn(formik.errors, cropIdName);
                                        const yieldName = `crops.${index}.yield`;
                                        const errorYield = getIn(formik.errors, yieldName);
                                        const isAccompaniedName = `crops.${index}.is_accompanied`;
                                        const errorisAccompanied = getIn(formik.errors, isAccompaniedName);
                                        const title = `${t(
                                            'pages.installationtasks.add-crop-task.crop-list-step.crop-title',
                                        )} ${index + 1}`;

                                        return (
                                            <Grid key={key} xs={12} item>
                                                <CropItemView
                                                    disabledForm={!canEditFarmSeason.access || isPermanentCrop(crop)}
                                                    cropError={Boolean(submitAttempted && errorCropId)}
                                                    cropHelperText={submitAttempted && errorCropId ? errorCropId : ''}
                                                    cropId={crop.crop.id}
                                                    index={index}
                                                    isCFT={isCFT}
                                                    isAccompanied={crop.is_accompanied ?? false}
                                                    isAccompaniedError={Boolean(submitAttempted && errorisAccompanied)}
                                                    isAccompaniedHelperText={
                                                        submitAttempted && errorisAccompanied ? errorisAccompanied : ''
                                                    }
                                                    isAccompaniedName={cropIdName}
                                                    title={
                                                        <>
                                                            <Typography variant="h5">{title}</Typography>
                                                            {crop.origin === 'suggestion' && (
                                                                <AutoFixHighIcon className={classes.titleIcon} />
                                                            )}
                                                            {crop.origin === 'capFile' && (
                                                                <FileUploadIcon className={classes.titleIcon} />
                                                            )}
                                                        </>
                                                    }
                                                    withHelp={index === 0}
                                                    yield={crop.yield || ''}
                                                    yieldError={Boolean(submitAttempted && errorYield)}
                                                    yieldHelperText={submitAttempted && errorYield ? errorYield : ''}
                                                    yieldName={yieldName}
                                                    onClose={() => onDeleteCrop({ index, crop, arrayHelpers })}
                                                    onCropChange={(cropId) => formik.setFieldValue(cropIdName, cropId)}
                                                    onRelationChange={(event) =>
                                                        formik.setFieldValue(isAccompaniedName, event.target.checked)
                                                    }
                                                    onYieldChange={(event) =>
                                                        formik.setFieldValue(yieldName, event.target.value)
                                                    }
                                                />
                                            </Grid>
                                        );
                                    })}
                                    <Grid xs={12} item>
                                        {canEditFarmSeason.access && (
                                            <AddButtonView
                                                label={t('constants.add')}
                                                onClick={() =>
                                                    arrayHelpers.push({
                                                        crop: {
                                                            id: undefined,
                                                        },
                                                        yield: undefined,
                                                        is_accompanied: false,
                                                    })
                                                }
                                            />
                                        )}
                                    </Grid>
                                </>
                            )}
                        />
                    </FormikProvider>
                    <Grid xs={6} item>
                        {canEditFarmSeason.access ? (
                            <LoadingButton
                                color="primary"
                                fontSize="0.9rem"
                                loading={formik.isSubmitting}
                                text={t('constants.save')}
                                type="submit"
                                fullWidth
                            />
                        ) : (
                            <Button fullWidth onClick={() => goToSelectFields()}>
                                {t('constants.continue')}
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </form>
            <ModalWarning
                controller={modalDeleteCrop}
                confirmMessage={t('pages.installationtasks.add-crop-task.crop-list-step.delete-modal.confirm-message')}
                description={t('pages.installationtasks.add-crop-task.crop-list-step.delete-modal.description')}
                leftButtonText={t('constants.cancel')}
                rightButtonText={t('constants.confirm')}
                title={t('pages.installationtasks.add-crop-task.crop-list-step.delete-modal.title')}
            />
            <ModalWarning
                controller={modalNoMatchingCropToImport}
                description={t(
                    'pages.installationtasks.add-crop-task.crop-list-step.modal-no-matching-crop-to-import.description',
                )}
                rightButtonText={t('constants.ok')}
                title={t('pages.installationtasks.add-crop-task.crop-list-step.modal-no-matching-crop-to-import.title')}
            />
            <ModalWarning
                controller={modalCropsToImportAlreadyExist}
                description={t(
                    'pages.installationtasks.add-crop-task.crop-list-step.modal-crops-to-import-already-exist.description',
                )}
                rightButtonText={t('constants.ok')}
                title={t(
                    'pages.installationtasks.add-crop-task.crop-list-step.modal-crops-to-import-already-exist.title',
                )}
            />
            <ModalWarning
                controller={modalCropsImportedLinkedToFields}
                description={t(
                    'pages.installationtasks.add-crop-task.crop-list-step.modal-crop-imported-linked-to-fields.description',
                )}
                rightButtonText={t('constants.ok')}
                title={t(
                    'pages.installationtasks.add-crop-task.crop-list-step.modal-crop-imported-linked-to-fields.title',
                )}
            />
            <ModalUploadCapFile controller={modalUploadCapFile} farmSeasonId={farmSeason.id} />
            {/* warning when delete a crop related to operations when any operation is done */}
            <ModalWarning
                controller={controllerWarningOperationDone}
                description={t('modal.warning.crop-linked-to-operations-give-error-on-delete')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.title')}
            />
            <ModalWarning
                controller={controllerWarningPermanentCrop}
                description={t('pages.crops.delete-modal.permanent-crop.description')}
                rightButtonText={t('constants.close')}
                title={t('modal.warning.title')}
            />
        </div>
    );
};

export default CropListStepView;
