import { Paper, Typography } from '@mui/material';
import { usePartnerInvitationCardStyles } from '@pages/InvitationFromPartnerPage/PartnerInvitationCard/PartnerInvitationCard.style';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type PartnerInvitationCardProps = {
    partnerName: string;
    actions: ReactNode;
};

export const PartnerInvitationCard = ({ partnerName, actions }: PartnerInvitationCardProps) => {
    const { t } = useTranslation();
    const { classes } = usePartnerInvitationCardStyles();

    return (
        <div className={classes.cardContainer}>
            <Paper className={classes.paper} elevation={0} variant="elevation">
                <div className={classes.wrapper}>
                    <Typography className={classes.title} variant="h4">
                        {t('pages.partner-invite.card.partner-invites-you', { partnerName })}
                    </Typography>
                    <div className={classes.paperContent}>
                        <Typography variant="body1">
                            {t('pages.partner-invite.card.why-join', { partnerName })}
                        </Typography>
                        <ul className={classes.bulletList}>
                            <li>
                                <Typography component="span" variant="body1">
                                    {t('pages.partner-invite.card.be-part-of')}
                                </Typography>
                            </li>
                            <li>
                                <Typography component="span" variant="body1">
                                    {t('pages.partner-invite.card.discuss-with')}
                                </Typography>
                            </li>
                        </ul>
                    </div>
                    <div className={classes.actionsContainer}>
                        <Typography variant="body1">
                            {t('pages.partner-invite.card.do-you-accept', { partnerName })}
                        </Typography>
                        <div className={classes.actions}>{actions}</div>
                    </div>
                </div>
            </Paper>
        </div>
    );
};
