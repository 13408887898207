import LoadingView from '@components/Loading/LoadingView';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppNavigate from '@hooks/useAppNavigate';
import useAppRoutes from '@hooks/useAppRoutes';
import useGaTracker from '@hooks/useGaTracker';
import useHotjar from '@hooks/useHotjar';
import { useHubspot } from '@hooks/useHubspot';
import useI18nSetup from '@hooks/useI18nSetup';
import useIntercomInitialization from '@hooks/useIntercomInitialization';
import useLocalStorageReset from '@hooks/useLocalStorageReset';
import useSegment from '@hooks/useSegment';
import useTriggerOnPage from '@hooks/useTriggerOnPage';
import useUserDataInitialization from '@hooks/useUserDataInitialization';
import useUserLanguageChanged from '@hooks/useUserLanguageChanged';
import AcceptInvitePage from '@pages/AcceptInvitePage/AcceptInvitePage';
import ErrorPage from '@pages/ErrorPage/ErrorPage';
import ForgotPasswordPage from '@pages/ForgotPasswordPage/ForgotPasswordPage';
import HomePage from '@pages/HomePage/HomePage';
import { InvitationFromPartnerPage } from '@pages/InvitationFromPartnerPage/InvitationFromPartnerPage';
import LoginPage from '@pages/LoginPage/LoginPage';
import MobileHardstopPage from '@pages/MobileHardstopPage/MobileHardstopPage';
import ResetPasswordPage from '@pages/ResetPasswordPage/ResetPasswordPage';
import SearchPage from '@pages/SearchPage/SearchPage';
import VisitFarmPage from '@pages/SearchPage/VisitFarm';
import SignUpPage from '@pages/SignUpPage/SignUpPage';
import SubscribePage from '@pages/SubscribePage/SubscribePage';
import VerifyEmailPage from '@pages/VerifyEmailPage/VerifyEmailPage';
import {
    canAccessSearchPage,
    canVisitFarm,
    checkHasNotSelectedSeasonOrHasInactivePlan,
    checkIsNotLoggedIn,
    checkLogin,
    checkUserRegistration,
    isAssociateUser,
    isFarmer,
    isFarmerInvitedByPartner,
    isFarmerNotInvitedByPartner,
    isNotMobile,
    isCFTfarmer,
} from '@services/guardsService/guardsService';
import { seasonGet } from '@store/actions/StaticActions/seasonGet';
import { AppStateT } from '@store/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const MainRouter = (): JSX.Element | null => {
    const translations = useSelector((state: AppStateT) => state.static.translations);
    const languages = useSelector((state: AppStateT) => state.static.languages);
    const routingState = useSelector((state: AppStateT) => state.routing);
    const initFarmsAndSeasonsLoading = useSelector((state: AppStateT) => state.app.initFarmsAndSeasonsLoading);
    const dispatch = useAppDispatch();

    const { pathname } = useLocation();
    const navigate = useAppNavigate();

    // Handle auth0 redirect
    useEffect(() => {
        if (pathname === '/logout') {
            navigate('/login');
        }
    }, [pathname]);

    // Init Segment.io Analytics V2
    useSegment();

    // Trigger some action based on location
    useTriggerOnPage();

    // Init Google Analytics if GA Token provided in env file
    useGaTracker();

    // Init Hotjar
    useHotjar();

    useHubspot();

    // Initiate Local Storage
    useLocalStorageReset();

    // Initiate I18next;
    useI18nSetup();

    // Add listener on user.language_id to switch language
    useUserLanguageChanged();

    // Init intercom
    useIntercomInitialization();

    useEffect(() => {
        dispatch(seasonGet());
    }, []);

    const { userDataInitializationLoading } = useUserDataInitialization();

    // Used with RoutingReducer to enable routing after Actions
    useEffect(() => {
        if (routingState?.redirectTo) {
            if (routingState?.redirectTo.startsWith('http://') || routingState?.redirectTo.startsWith('https://')) {
                window.location.replace(`${routingState.redirectTo}`);
            } else {
                navigate(routingState.redirectTo);
            }
        }
    }, [routingState]);

    const mainRouter = useAppRoutes([
        {
            path: '/mobile-hardstop',
            element: <MobileHardstopPage />,
        },
        {
            path: '/signup',
            element: <SignUpPage />,
            guards: [checkIsNotLoggedIn],
        },
        {
            path: '/login',
            element: <LoginPage />,
            guards: [checkIsNotLoggedIn],
        },
        {
            path: '/forgot-password',
            element: <ForgotPasswordPage />,
        },
        {
            path: '/reset-password/:resetCode',
            element: <ResetPasswordPage />,
        },
        {
            path: '/accept-invite/:id',
            element: <AcceptInvitePage />,
        },
        {
            path: '/accept-partner-invite',
            element: <InvitationFromPartnerPage />,
            guards: [checkLogin, isNotMobile, isFarmer, isFarmerInvitedByPartner],
        },
        {
            path: '/verify-email',
            element: <VerifyEmailPage />,
        },
        {
            path: '/verify-email/:hashedId/:expires/:signature',
            element: <VerifyEmailPage />,
        },
        {
            path: '/error',
            element: <ErrorPage />,
        },
        {
            path: '/search',
            element: <SearchPage />,
            guards: [checkLogin, isNotMobile, isAssociateUser, canAccessSearchPage],
        },
        {
            path: '/visit-farm/:farmerId',
            element: <VisitFarmPage />,
            guards: [checkLogin, isNotMobile, canVisitFarm],
        },
        {
            path: '/subscribe/*',
            element: <SubscribePage />,
            guards: [checkLogin, isNotMobile, checkHasNotSelectedSeasonOrHasInactivePlan, isFarmerNotInvitedByPartner],
        },
        {
            path: '/*',
            element: <HomePage />,
            guards: [checkLogin, isNotMobile, checkUserRegistration, isCFTfarmer],
        },
    ]);

    if (
        !translations ||
        !languages ||
        initFarmsAndSeasonsLoading || // needed for farmer selection
        userDataInitializationLoading
    ) {
        return <LoadingView />;
    }

    return mainRouter;
};

export default MainRouter;
