import { makeStyles } from 'tss-react/mui';
import theme from '@theme/theme';

export const usePartnerInvitationCardStyles = makeStyles()(() => ({
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    paper: {
        maxWidth: '450px',
        boxShadow: '2px 2px 4px 1px rgba(216, 183, 149, 0.05)',
        borderRadius: '20px',
        background: '#fff',
        margin: 'auto',
        padding: '0px 60px 40px 60px',
        position: 'absolute',
        left: '50%',
        top: '50%',
        translate: '-50% -50%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'content-box',
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        marginTop: '20px',
        marginBottom: '24px',
        color: theme.palette.primary.light,
        fontSize: '22px',
        '@media (max-height: 768px)': {
            marginTop: '10px',
        },
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    },
    bulletList: {
        paddingLeft: '12px',
    },
    actionsContainer: {
        marginTop: '32px',
    },
    actions: {
        marginTop: '16px',
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
    },
}));
