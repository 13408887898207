import useAppDispatch from '@hooks/useAppDispatch';
import { AppStateT } from '@store/store';
import { useSelector } from 'react-redux';
import getLatestProductPlanStatus from '@/store/actions/ProductPlanActions/getLatestProductPlanStatus';
import { APP_SIMULATION_TOOL_TOGGLE_MODAL } from '@actions/AppActions/AppActionsTypes';
import SelectionStepView from './SelectionStepView';

const SelectionStep = (): JSX.Element | null => {
    // const [loading, setLoading] = useState(false);
    const user = useSelector((store: AppStateT) => store.auth.user);
    // const { i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const farmId = useSelector((store: AppStateT) => store.farm.currentFarm?.id);
    if (farmId) {
        dispatch(getLatestProductPlanStatus({ farmId }));
    }
    const toggleModal = () => {
        dispatch({ type: APP_SIMULATION_TOOL_TOGGLE_MODAL });
    };

    if (!user) {
        return null;
    }

    return <SelectionStepView loading={false} toggleModal={toggleModal} user={user} />;
};

export default SelectionStep;
