import { GuardT } from '@services/guardsService/guardsService';
import selectSeasonProductPlan from '@store/selectors/selectProductPlan';
import store from '@store/store';

const productPlanIsPayable: GuardT<'/subscribe/select-season'> = () => {
    const seasonProductPlan = selectSeasonProductPlan()(store.getState());

    const mustBePaid =
        //seasonProductPlan?.product_plan?.name === 'STANDARD' &&
        seasonProductPlan?.status === 'to_pay' || seasonProductPlan?.status === 'to_choose';

    return {
        passed: mustBePaid,
        redirect: '/subscribe/select-season',
    };
};

export default productPlanIsPayable;
