import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CardFlow from '../components/CardFlow/CardFlow';
import ProgressWrapper from '../components/ProgressWrapper/ProgressWrapper';
import PaymentForm from '@components/PaymentForm/PaymentForm';
import { PaymentFormRefControllerT } from '@components/PaymentForm/PaymentForm.types';
import { useSelector } from 'react-redux';
import { AppStateT } from '@store/store';
import getLatestProductPlanStatus from '@/store/actions/ProductPlanActions/getLatestProductPlanStatus';
import useAppDispatch from '@hooks/useAppDispatch';

const PaymentStepView = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(false);
    const formRef = useRef<PaymentFormRefControllerT>(null);
    const farmId = useSelector((state: AppStateT) => state.farm.currentFarm?.id);

    if (farmId) {
        dispatch(getLatestProductPlanStatus({ farmId }));
    }

    return (
        <CardFlow>
            <ProgressWrapper
                handleNextButton={async () => await formRef.current?.redirectToPayment()}
                nextButtonLoading={isSubmitting}
                nextButtonDisabled={initialLoading}
                nextButtonText={t('constants.continue')}
                subTitle={t('pages.subscribe.payment.sub-title')}
                title={t('pages.subscribe.payment.title')}
                showBackLink
            >
                <PaymentForm
                    withUseFarmDataCheckbox
                    type="first"
                    ref={formRef}
                    setIsSubmitting={setIsSubmitting}
                    setInitialLoading={setInitialLoading}
                />
            </ProgressWrapper>
        </CardFlow>
    );
};

export default PaymentStepView;
