/* eslint-disable @typescript-eslint/no-explicit-any */
import authService from '@services/authService/authService';
import SegmentService from '@services/segmentService/segmentService';

import { appInitUserData } from '../AppActions/AppActions';
import {
    SIGNUP_FAILED,
    SIGNUP_LOADING,
    SIGNUP_SUCCESS,
    SignUpActionDataT,
    SignUpPayloadT,
    SignUpResponseErrorT,
} from './SignUpActionsTypes';
import UserAddAmbassador from './UserAddAmbassador';

const signUp =
    (
        inputValues: SignUpActionDataT,
        language_id?: number,
        token?: string,
        invitation_id?: number,
    ): any => // eslint-disable-line
    async (dispatch: any): Promise<SignUpResponseErrorT> => {
        try {
            dispatch({ type: SIGNUP_LOADING });

            const res: SignUpPayloadT = await authService.register({
                language_id,
                ...inputValues,
                token,
                invitation_id,
            });

            // MSC-1894
            SegmentService.identify(res.data?.user?.id, {
                id: res.data?.user?.id,
                firstname: res.data?.user?.first_name,
                lastname: res.data?.user?.last_name,
                email: res.data?.user?.email,
            });

            await dispatch(appInitUserData({ user: res.data.user }));

            const referralCode = res.data?.ambassador_referral_code;
            dispatch(UserAddAmbassador(referralCode));

            dispatch({
                type: SIGNUP_SUCCESS,
                payload: res.data,
            });

            return {};
        } catch (e: any) {
            const list: string[] = [];

            if (e.response?.data?.message === 'The email has already been taken.') {
                list.push('register-email-already-taken');
            }

            if (e.response?.data.message === 'register.referral_code.not_found') {
                list.push(e.response?.data.message);
            }

            dispatch({
                type: SIGNUP_FAILED,
                error: {
                    message: e.response?.data || e.message,
                    list,
                },
            });

            return {
                message: e.response?.data || e.message,
                list,
            };
        }
    };

export default signUp;
