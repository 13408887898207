import { makeStyles } from 'tss-react/mui';
import field from '@images/dark-field.jpg';

const useSubscribePageViewStyle = makeStyles<{ showTopBanner?: boolean }>()((theme, { showTopBanner }) => ({
    container: {
        padding: 0,
        height: '100%',
        minHeight: showTopBanner ? 'calc(100vh - 48px)' : '100vh',
        position: 'relative',
        background: 'rgba(226, 208, 182, 0.15)',
    },
    background: {
        position: 'absolute',
        bottom: 0,
        width: '100vw',
        maxWidth: '1920px',
    },
    darkBackground: {
        height: '20vh',
        width: '100%',
        background: 'rgba(230, 208, 182, 0.30)',
    },
    imageContainer: {
        height: '35vh',
        width: '100%',
        overflow: 'hidden',
        background: `url(${field}) no-repeat`,
        backgroundPosition: 'top -120px right 0px',
        '@media (max-width: 1600px)': {
            backgroundPosition: 'top -120px right -100px',
        },
        '@media (max-width: 1366px)': {
            backgroundPosition: 'top -120px right -300px',
        },
        '@media (max-width: 1200px)': {
            backgroundPosition: 'top -120px right -500px',
        },
    },
    content: {
        position: 'absolute',
        width: 'calc(100% - 30px)',
        padding: '0 15px',
        top: 0,
        left: 0,
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        '@media (max-height: 1080px)': {
            paddingTop: '10vh',
        },
        '@media (max-height: 900px)': {
            paddingTop: '5vh',
        },
        '@media (max-height: 700px)': {
            paddingTop: 0,
        },
    },
    appBarWrapper: {},
}));

export default useSubscribePageViewStyle;
