import { makeStyles } from 'tss-react/mui';
import { SelectSeasonStyleProps } from './SelectSeasonTypes';

const useSelectSeasonStyle = makeStyles<SelectSeasonStyleProps>()((theme, { justifyContent }) => ({
    flexContaier: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: justifyContent,
        alignItems: 'center',
        height: '80%',
        marginTop: '10%',
    },
    PaymentStatusIcon: {
        marginBottom: '20px',
        width: '100px',
        height: '100px',
        color: '#36B37F',
        '&>div': {
            height: 'auto', // unset loader height
        },
        '& .MuiSvgIcon-root': {
            width: '100%',
            height: '100%',
        },
    },
    iconDropShadow: {
        filter: 'drop-shadow(0px 3px 5px rgb(0 0 0 / 20%))',
    },
    selectContainer: {
        marginBottom: theme.spacing(4),
        minWidth: '160px',
    },
    title: {
        fontSize: '26px',
        textAlign: 'center',
    },
    body: {
        margin: justifyContent === 'start' ? '50px -10px 30px -10px' : '20px auto 30px auto',
        textAlign: 'center',
    },
}));

export default useSelectSeasonStyle;
