import SubscriptionApiService from '@services/subscriptionApiService/subscriptionApiService';
import { AxiosResponse } from 'axios';
import { Dispatch } from 'react';

import {
    SubscriptionSaveProductPlanDataT,
    SubscriptionSaveProductPlanActionsT,
    SubscriptionSaveProductPlanSuccessDataT,
    SUBSCRIPTION_SAVE_PRODUCT_PLAN_SUCCESS,
} from './SubscriptionSaveProductPlanTypes';

const subscriptionSaveFarmSeasonProductPlan =
    (subscription: SubscriptionSaveProductPlanDataT) =>
    async (dispatch: Dispatch<SubscriptionSaveProductPlanActionsT>) => {
        try {
            const res: AxiosResponse<SubscriptionSaveProductPlanSuccessDataT> =
                await SubscriptionApiService.updateFarmSeasonProductPlan(subscription);

            dispatch({
                type: SUBSCRIPTION_SAVE_PRODUCT_PLAN_SUCCESS,
                payload: res.data,
            });

            return res.data.farmSeasonProductPlan;
        } catch (error) {
            return false;
        }
    };

export default subscriptionSaveFarmSeasonProductPlan;
