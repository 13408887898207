import { useTranslation } from 'react-i18next';
import PaymentDoneCard from '@components/PaymentDoneCard/PaymentDoneCard';
import { PaymentStatusT } from '@/components/PaymentStatusIcon/PaymentStatusIconTypes';
import useAppNavigate from '@hooks/useAppNavigate';

import usePaymentDoneLogic from './PaymentDone.logic';
import usePaymentDoneStyle from './PaymentDone.style';

const PaymentDone = (): JSX.Element => {
    const { t } = useTranslation();
    const { paymentStatus, hasServerError, handleNextClicked, nextButtonLoading, mainButtonText, text } =
        usePaymentDoneLogic();
    const { classes } = usePaymentDoneStyle();
    const navigate = useAppNavigate();

    return (
        <PaymentDoneCard
            hasServerError={hasServerError}
            overrides={{
                EndWrapper: ({ $paymentStatus }) => ({
                    secondaryButtonText: $paymentStatus === 'canceled' ? t('constants.go-back-to-welcome') : undefined,
                    mainButtonText: paymentStatus ? mainButtonText[paymentStatus] : '',
                    text: paymentStatus ? text[paymentStatus] : '',
                    handleMainButton: handleNextClicked,
                    mainButtonLoading: nextButtonLoading,
                    handleSecondaryButton: () => navigate('/subscribe'),
                    justifyContent: $paymentStatus !== 'canceled' ? 'start' : 'center',
                }),
            }}
            paymentStatus={paymentStatus}
        />
    );
};

export default PaymentDone;
