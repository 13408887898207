import { UserInvitationI } from '@/services/partnerInvitationApiService/partnerInvitationApiServiceTypes';
import { api } from './api.service';

export const invitationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        acceptInvite: builder.mutation<UserInvitationI, { invitationId: number; fromInvitationEmail: boolean }>({
            query: ({ invitationId, fromInvitationEmail }) => ({
                url: `${process.env.REACT_APP_PP_API_BASE_URL}/private/invitations/farmer/${invitationId}/accept?fromInvitationEmail=${fromInvitationEmail}`,
                method: 'PUT',
            }),
            invalidatesTags: ['Invitation'],
        }),
        rejectInvite: builder.mutation<UserInvitationI, { invitationId: number; fromInvitationEmail: boolean }>({
            query: ({ invitationId, fromInvitationEmail }) => ({
                url: `${process.env.REACT_APP_PP_API_BASE_URL}/private/invitations/farmer/${invitationId}/reject?fromInvitationEmail=${fromInvitationEmail}`,
                method: 'PUT',
            }),
            invalidatesTags: ['Invitation'],
        }),
    }),
});

export const { useAcceptInviteMutation, useRejectInviteMutation } = invitationApi;
