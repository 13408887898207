import SelectionStep from '@/pages/SubscribePage/SelectionStep/SelectionStep';
import useAppRoutes from '@hooks/useAppRoutes';
import ContractStep from '@pages/SubscribePage/ContractStep/ContractStep';
import EligibilityStep from '@pages/SubscribePage/EligibilityStep/EligibilityStep';
import FarmStep from '@pages/SubscribePage/FarmStep/FarmStep';
import LivestockStep from '@pages/SubscribePage/LivestockStep/LivestockStep';
import PaymentDone from '@pages/SubscribePage/PaymentDone/PaymentDone';
import PaymentStep from '@pages/SubscribePage/PaymentStep/PaymentStep';
import ProductPlanStep from '@pages/SubscribePage/ProductPlanStep/ProductPlanStep';
import SelectSeason from '@pages/SubscribePage/SelectSeason/SelectSeason';
import {
    checkEmailIsVerified,
    isFarmer,
    isNotDeferredPayment,
    isNotFromArgentina,
    hasAcceptedTerms,
} from '@services/guardsService/guardsService';
import { Navigate } from 'react-router-dom';
import productPlanIsActivable from './productPlanIsActivable.guard';

const SubscribePageRouter = (): JSX.Element | null =>
    useAppRoutes([
        { path: '/closed', element: <SelectionStep />, guards: [isFarmer, isNotFromArgentina, isNotDeferredPayment] },
        { path: '/farm', element: <FarmStep />, guards: [checkEmailIsVerified, isFarmer] },
        { path: '/livestock', element: <LivestockStep />, guards: [checkEmailIsVerified, isFarmer] },
        {
            path: '/eligibility/*',
            element: <EligibilityStep />,
            guards: [checkEmailIsVerified, isFarmer],
        },
        {
            path: '/product-plan',
            element: <ProductPlanStep />,
            // productPlanIsSelectable should be updated. but as this flow will be refactored in V3 we simply remove the guard
            guards: [checkEmailIsVerified, /* productPlanIsSelectable, */ isFarmer, isNotDeferredPayment],
        },
        { path: '/contract', element: <ContractStep />, guards: [checkEmailIsVerified, isFarmer] },
        {
            path: '/payment',
            element: <PaymentStep />,
            guards: [checkEmailIsVerified, isFarmer, isNotFromArgentina, isNotDeferredPayment],
        },
        {
            path: '/payment-status/:stripeStatus',
            element: <PaymentDone />,
            guards: [checkEmailIsVerified, isFarmer, isNotDeferredPayment],
        },
        {
            path: '/select-season',
            element: <SelectSeason />,
            guards: [checkEmailIsVerified, productPlanIsActivable, hasAcceptedTerms],
        },
        { path: '*', element: <Navigate to="farm" replace /> },
    ]);

export default SubscribePageRouter;
