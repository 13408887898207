import { useCanVisitFarm, useIsAssociateUser, useIsReadOnlyUser } from '@/hooks/usePermissions';
import { ROLE_ID } from '@constants/roleIds';
import selectIsProductPlanActive from '@store/selectors/selectIsProductPlanActive';
import selectSeasonProductPlan from '@store/selectors/selectProductPlan';
import store from '@store/store';

export type GuardT<T = string> = () => { passed: boolean; redirect: T };

export const checkLogin: GuardT = () => {
    if (store.getState().auth.user?.email_verification_status === 'expired') {
        return {
            passed: false,
            redirect: '/verify-email',
        };
    }

    return {
        passed: !!store.getState().auth.user?.id,
        redirect: '/signup',
    };
};

export const checkIsNotLoggedIn: GuardT = () => ({
    passed: !store.getState().auth.user?.id,
    redirect: '/',
});

export const checkEmailIsVerified: GuardT = () => ({
    passed: store.getState().auth.user?.email_verification_status === 'verified',
    redirect: '/verify-email',
});

export const checkDontHaveFarm: GuardT = () => ({
    passed: store.getState().auth.user?.farm_count === 0,
    redirect: '/',
});

export const checkUserRegistration: GuardT = () => {
    const user = store.getState().auth.user;
    const isFarmer = user?.role_id === ROLE_ID.FARMER;
    const isFromArgentina = user?.user_invitation?.partner?.country_id === 2 ?? false;
    const pendingInvitation = store.getState().invitation.pendingPartnerInvitation;
    const hasFinishedRegistration =
        Boolean(store.getState().farmSeason.currentFarmSeason?.season_id) &&
        selectIsProductPlanActive()(store.getState());

    return {
        passed: hasFinishedRegistration,
        redirect: !isFarmer
            ? '/search'
            : pendingInvitation
            ? '/accept-partner-invite'
            : isFromArgentina
            ? '/subscribe/farm'
            : '/subscribe',
    };
};

export const checkHasNotSelectedSeasonOrHasInactivePlan: GuardT = () => {
    const seasonProductPlan = selectSeasonProductPlan()(store.getState());

    return {
        passed: !store.getState().farmSeason?.currentFarmSeason?.season_id || seasonProductPlan?.status === 'inactive',
        redirect: '/',
    };
};

export const isAgronomistWithSelectedFarm: GuardT = () => {
    const user = store.getState().auth.user;

    return {
        passed: !!user && user.role_id !== ROLE_ID.FARMER && !!store.getState().farm.currentFarm,
        redirect: '/search',
    };
};

export const isFarmer: GuardT = () => {
    const user = store.getState().auth.user;

    return {
        passed: !!(user && user?.role_id === ROLE_ID.FARMER),
        redirect: '/search',
    };
};

export const isNotFromArgentina: GuardT = () => {
    const user = store.getState().auth.user;
    const countryId = user?.user_invitation?.partner?.country_id;
    const isInvitationRejected = Boolean(user?.user_invitation?.rejected_at);
    const isNotFromArgentina = countryId !== 2;

    return {
        passed: isNotFromArgentina || isInvitationRejected,
        redirect: '/subscribe/select-season',
    };
};

export const isAssociateUser: GuardT = () => {
    const isAssociateUser = useIsAssociateUser();

    return {
        passed: isAssociateUser,
        redirect: '/',
    };
};

export const canVisitFarm: GuardT = () => {
    const result = useCanVisitFarm();
    return {
        passed: result,
        redirect: '/error',
    };
};

export const isNotReadOnlyUser: GuardT = () => {
    const result = useIsReadOnlyUser();
    return {
        passed: !result,
        redirect: '/error',
    };
};

export const isNotMobile: GuardT = () => {
    const UA = window.navigator.userAgent;
    // Strategy comes from https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_tablet_or_desktop
    // exception added for ipad. 'Mobi' doesn't looks ok for ios to differenciate tablet and mobile phone. Contrarry to android devices: https://developers.google.com/search/blog/2011/03/mo-better-to-also-detect-mobile-user
    const isMobile = /Mobi/i.test(UA) && !/iPad/i.test(UA);

    return { passed: !isMobile, redirect: '/mobile-hardstop' };
};

export const hasResultAccessGuard: GuardT = () => {
    const farmSeason = store.getState().farmSeason.currentFarmSeason;
    const userAuth = store.getState().auth.user;

    return {
        passed: !!(
            farmSeason &&
            farmSeason.has_results &&
            ((farmSeason.results_validated_by_agronomist &&
                (((farmSeason.has_results_access || farmSeason.one_payment) && // FP-3136 - one_payment don't need to have has_results_access (second payment)
                    [0, 1].includes(farmSeason.partner_type)) ||
                    [2, 3, 4, null].includes(farmSeason?.partner_type))) ||
                (userAuth && userAuth.role_id !== ROLE_ID.FARMER))
        ), // MSC-1903 !hasResults || !(farmSeason.partner_type !== 2 && farmSeason.has_results_access),
        redirect: '/',
    };
};

export const hasCertificatesGuard: GuardT = () => {
    const hasCertificates = !!store.getState().farmSeason?.currentFarmSeason?.has_certificates;

    return {
        passed: !!hasCertificates,
        redirect: '/',
    };
};

export const hasDataSummaryAccessGuard: GuardT = () => {
    const currentFarmSeason = store.getState().farmSeason?.currentFarmSeason;
    const hasAgronomFinalizedSeason = !!currentFarmSeason?.operations_validated_at;
    const hasFarmerValidatedSeason = !!currentFarmSeason?.summary_validated_at;
    const isReadOnlyUser = useIsReadOnlyUser();

    return {
        passed: hasAgronomFinalizedSeason && (!isReadOnlyUser || hasFarmerValidatedSeason),
        redirect: '/',
    };
};

export const hasOnboardingFieldDoneGuard: GuardT = () => {
    const onboarding = store.getState().farmSeason?.currentFarmSeason?.installation?.onboarding;
    return {
        passed: onboarding?.field?.done === true,
        redirect: '/',
    };
};

export const isInstallationOpenedIfAuthFarmerGuard: GuardT = () => {
    const installationConfirmed = !!store.getState().farmSeason?.currentFarmSeason?.installation_validated_at;
    const isFarmer = store.getState().auth.user?.role_id === ROLE_ID.FARMER;
    const installationOpened = !installationConfirmed;
    return {
        passed: isFarmer ? installationOpened : true,
        redirect: '/',
    };
};

export const canAccessSearchPage = () => {
    const permissions = store.getState().app.permissions;
    const canSeeSearchPage = !!permissions?.ACCESS_PAGE?.find((p) => p.scope === 'SEARCH');
    const user = store.getState().auth.user;

    return {
        passed: canSeeSearchPage,
        redirect: user ? '/error' : '/login',
    };
};

export const isFarmerInvitedByPartner = () => {
    const pendingInvitation = store.getState().invitation.pendingPartnerInvitation;

    return {
        passed: !!pendingInvitation,
        redirect: '/subscribe',
    };
};

export const isFarmerNotInvitedByPartner = () => {
    const pendingInvitation = store.getState().invitation.pendingPartnerInvitation;

    return {
        passed: !pendingInvitation,
        redirect: '/accept-partner-invite',
    };
};

export const isNotDeferredPayment = () => {
    const farmSeasonProductPlan = selectSeasonProductPlan()(store.getState());

    return {
        passed: farmSeasonProductPlan?.status !== 'active' && !farmSeasonProductPlan?.deferred_payment_start_date,
        redirect: '/subscribe/select-season',
    };
};

export const hasAcceptedTerms = () => {
    const hasSignedTerms = store.getState().subscription.tacSigned;

    return {
        passed: !!hasSignedTerms,
        redirect: '/subscribe/contract',
    };
};

export const isCFTfarmer = () => {
    const isCFTfarmer = store.getState().auth.farmer_infos?.model_type === 'cft';

    if (!isCFTfarmer && isFarmer().passed) {
        window.location.href = process.env.REACT_APP_V3_BASE_URL;
    }

    return {
        passed: isCFTfarmer || !isFarmer().passed,
        redirect: '/',
    };
};
