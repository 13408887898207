import FarmSeasonApiService from '@services/farmSeasonApiService/farmSeasonApiService';
import { UpdateFarmSeasonDataT } from '@services/farmSeasonApiService/FarmSeasonApiServiceTypes';
import { Dispatch } from '@reduxjs/toolkit';

import {
    FarmSeasonUpdateDispatchTypes,
    FarmSeasonUpdateFailedDataT,
    FARM_SEASON_UPDATE_FAILED,
    FARM_SEASON_UPDATE_LOADING,
    FARM_SEASON_UPDATE_SUCCESS,
} from './FarmSeasonUpdateTypes';

/** update farmSeason to set the current farm season */
const farmSeasonUpdateAction =
    (farmId: number, farmSeasonId: number, payload: UpdateFarmSeasonDataT) =>
    async (dispatch: Dispatch<FarmSeasonUpdateDispatchTypes>): Promise<boolean> => {
        dispatch({ type: FARM_SEASON_UPDATE_LOADING, payload: true });

        try {
            const res = await FarmSeasonApiService.updateFarmSeason(farmId, farmSeasonId, payload);
            dispatch({ type: FARM_SEASON_UPDATE_SUCCESS, payload: { ...res.data.farmSeason } });

            return true;
        } catch (error) {
            dispatch({
                type: FARM_SEASON_UPDATE_FAILED,
                error: error as FarmSeasonUpdateFailedDataT,
            });

            return false;
        }
    };

export default farmSeasonUpdateAction;
