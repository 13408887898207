import { countryGet } from '@actions/StaticActions/StaticAction';
import { AppStateT } from '@store/store';
import useAppDispatch from '@hooks/useAppDispatch';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';
import * as yup from 'yup';
import SegmentService from '@services/segmentService/segmentService';

import { FarmOnboardingDataT } from './FarmStepTypes';
import FarmStepView from './FarmStepView';
import subscriptionSaveFarm from '@actions/SubscriptionActions/SubscriptionSaveFarm';

const FarmStep = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { t } = useTranslation();
    const countries = useSelector((state: AppStateT) => state.static.countries);
    const farm = useSelector((state: AppStateT) => state.farm.currentFarm);
    const farmSeason = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason);
    const user = useSelector((state: AppStateT) => state.auth.user);
    const [serverError, setServerError] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);

    const validationSchema = yup.object({
        name: yup.string().required(t('validation.no-farm-name-provided')),
        eligibility_area: yup
            .number()
            .positive(t('validation.cannot-be-negative'))
            .required(t('validation.missing-area')),
        address: yup.string().required(t('validation.no-address-provided')).max(120, t('validation.max-chars-120')),
        postal_code: yup.string().required(t('validation.no-postal-code-provide')),
        city: yup.string().required(t('validation.no-city-provided')),
        country: yup
            .object()
            .shape({
                id: yup.number(),
            })
            .typeError(t('validation.no-country-provided')),
        phone_number: yup.number().required(t('validation.missing-phone')),
    });

    // Formik form
    const formik = useFormik({
        initialValues: {
            name: farm?.name ?? '',
            eligibility_area: farmSeason?.eligibility_area?.toString() ?? '',
            eligibility_has_livestock: farmSeason?.eligibility_has_livestock ?? false,
            address: farm?.address ?? '',
            postal_code: farm?.postal_code ?? '',
            city: farm?.city ?? '',
            country: farm?.country_id
                ? countries?.find((country) => country.id === parseInt(farm?.country_id, 10)) ?? null
                : null,
            phone_number: user?.phone_number ?? '',
            farmId: farm?.id ?? null,
            farmSeasonId: farmSeason?.id ?? null,
        },
        onSubmit: async (values: FarmOnboardingDataT) => {
            if (!values.country?.id) {
                throw Error('country id is required for "subscriptionSaveFarm"');
            }
            setServerError('');
            setSubmitLoading(true);

            const { error, data } = await dispatch(
                subscriptionSaveFarm({
                    name: values.name,
                    address: values.address,
                    postal_code: values.postal_code,
                    country_id: values.country.id,
                    phone_number: values.phone_number,
                    city: values.city,
                    eligibility_area: parseInt(values.eligibility_area),
                    eligibility_has_livestock: values.eligibility_has_livestock,
                    farmId: values.farmId,
                    farmSeasonId: values.farmSeasonId,
                }),
            );

            if (error) {
                setServerError(t('constants.server-error'));
                setSubmitLoading(false);
                return;
            }

            const eligibility_status = data?.eligibility_status;

            // MSC-1895
            SegmentService.identify(user?.id, {
                address: {
                    phone: values.phone_number,
                    street: values.address,
                    city: values.city,
                    country: values.country?.name,
                    postalCode: values.postal_code,
                },
            });

            if (values.eligibility_has_livestock) {
                navigate('/subscribe/livestock');
            } else {
                navigate(`/subscribe/eligibility/${eligibility_status}`);
            }
        },
        validationSchema,
        validateOnBlur: true,
        enableReinitialize: true,
    });

    useEffect(() => {
        dispatch(countryGet());
    }, []);

    return (
        <FarmStepView countries={countries} formik={formik} serverError={serverError} submitLoading={submitLoading} />
    );
};

export default FarmStep;
