import { Modal } from '@mui/material';
import CardFlow from '@pages/SubscribePage/components/CardFlow/CardFlow';
import ProgressWrapper from '@pages/SubscribePage/components/ProgressWrapper/ProgressWrapper';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useModalPaymentStyle from './ModalPayment.style';
import { ModalPaymentPrposT } from './ModalPaymentTypes';
import PaymentForm from '@components/PaymentForm/PaymentForm';
import { PaymentFormRefControllerT } from '@components/PaymentForm/PaymentForm.types';

const ModalPayment = ({ controller }: ModalPaymentPrposT): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useModalPaymentStyle();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [initialLoading, setInitialLoading] = useState<boolean>(false);
    const formRef = useRef<PaymentFormRefControllerT>(null);

    const handleSubmitData = async () => {
        await formRef.current?.redirectToPayment();
        controller.onModalConfirm();
    };

    const handleCancel = () => {
        controller.onModalCancel();
    };

    return (
        <Modal open={controller.isModalOpen} disableScrollLock>
            <div className={classes.modalContent}>
                <CardFlow>
                    <ProgressWrapper
                        handleNextButton={handleSubmitData}
                        handleSkipLinkClick={handleCancel}
                        nextButtonLoading={initialLoading || isSubmitting}
                        nextButtonText={t('constants.continue')}
                        nextButtonUrl="/signup"
                        skipLinkText={t('constants.cancel')}
                        subTitle={t('pages.farmer-data.payment-detail.sub-title')}
                        title={t('pages.farmer-data.payment-detail.title')}
                    >
                        <PaymentForm
                            type="second"
                            ref={formRef}
                            setIsSubmitting={setIsSubmitting}
                            setInitialLoading={setInitialLoading}
                        />
                    </ProgressWrapper>
                </CardFlow>
            </div>
        </Modal>
    );
};

export default ModalPayment;
