import farmSeasonUpdateAction from '@actions/FarmSeasonActions/FarmSeasonUpdate';
import { AppStateT } from '@store/store';
import useAppDispatch from '@hooks/useAppDispatch';
import { useSelector } from 'react-redux';
import useAppNavigate from '@hooks/useAppNavigate';
import selectCurrentSeason from '@/store/selectors/selectCurrentSeason';
import { isNotFromArgentina } from '@/services/guardsService/guardsService';

const useSelectSeasonLogic = () => {
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();

    // Context
    const farmId = useSelector((state: AppStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason?.id);
    const season = useSelector(selectCurrentSeason());

    // Store
    const nextButtonLoading = useSelector((state: AppStateT) => state.farmSeason?.updateLoading);
    const hasServerError = useSelector((state: AppStateT) => !!state.farmSeason?.updateError);

    const handleNextClicked = async () => {
        if (!farmId || !farmSeasonId || !season) {
            throw Error('farmId, farmSeasonId, seasonId are required to call "farmSeasonUpdateAction"');
        }

        // create farmSeason
        const success = await dispatch(farmSeasonUpdateAction(farmId, farmSeasonId, { season_id: season?.id }));

        // redirect to v3 if it's not an Argentinian farmer
        if (success) {
            if (isNotFromArgentina().passed) {
                window.location.replace(process.env.REACT_APP_V3_BASE_URL);
            } else navigate('/');
        }
    };

    return {
        handleNextClicked,
        nextButtonLoading,
        hasServerError,
    };
};

export default useSelectSeasonLogic;
