import { UPDATE_USER_ELIGIBILITY } from '@actions/AuthActions/GetUserActionsTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppNavigate from '@hooks/useAppNavigate';
import UserApiService from '@services/userApiService/userApiService';
import { AppStateT } from '@store/store';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AREA_ELIGIBLE } from '@constants/eligibility';
import productPlanIsSelectable from '@routers/SubscribePageRouter/productPlanIsSelectable.guard';
import { Navigate, useLocation } from 'react-router-dom';
import CardFlow from '../components/CardFlow/CardFlow';
import EndWrapper from '../components/EndWrapper/EndWrapper';

import { STANDARD_PLAN_ID } from '@/constants/productPlanIds';
import getLatestProductPlanStatus from '@/store/actions/ProductPlanActions/getLatestProductPlanStatus';
import selectSeasonProductPlan from '@/store/selectors/selectProductPlan';
import useProductPlanStepLogic from '../ProductPlanStep/ProductPlanStep.logic';

const EligibilityStepView = (): JSX.Element => {
    const { t } = useTranslation();
    const [continued, setContinued] = useState(false);
    const farmSeason = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason);
    const farm = useSelector((state: AppStateT) => state.farm.currentFarm);
    const user = useSelector((state: AppStateT) => state.auth.user);
    const isSelectableGuard = productPlanIsSelectable();

    const [loading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useAppNavigate();
    const { pathname } = useLocation();
    const pathElements = pathname.split('/');
    const eligibilityStatus = pathElements[pathElements.length - 1];

    if (farm) {
        dispatch(getLatestProductPlanStatus({ farmId: farm.id }));
    }

    const seasonProductPlan = useSelector(selectSeasonProductPlan());
    const { handleSaveProductPlan } = useProductPlanStepLogic({ seasonProductPlan });

    const eligibilityScreen = useCallback(() => {
        if (user?.is_eligible === false) {
            // NOT ELIGIBLE
            return (
                <EndWrapper
                    handleMainButton={() => navigate('/subscribe')}
                    mainButtonText={t('constants.go-back-to-welcome')}
                    text={t('pages.subscribe.eligibility.description.' + eligibilityStatus)}
                    title={t('pages.subscribe.eligibility.not-eligible.title')}
                    titleColor="error"
                />
            );
        }

        if (!continued && farmSeason?.eligibility_area && farmSeason.eligibility_area < AREA_ELIGIBLE) {
            // AREA < 50ha
            return (
                <EndWrapper
                    handleMainButton={async () => {
                        if (!farm?.id || !farmSeason?.id) {
                            throw Error('farm.id and farmSeason.id are required to call "UserApiService.setEligible"');
                        }

                        setLoading(true);

                        const res = await UserApiService.setEligible(farm?.id, farmSeason?.id).catch((e) => {
                            setLoading(false);

                            throw e;
                        });
                        dispatch({ type: UPDATE_USER_ELIGIBILITY, payload: { is_eligible: res.data.is_eligible } });

                        setContinued(true);
                        setLoading(false);
                        navigate('/subscribe/eligibility');
                    }}
                    handleSecondaryButton={() => navigate('/subscribe')}
                    mainButtonLoading={loading}
                    mainButtonText={t('constants.continue')}
                    secondaryButtonText={t('constants.cancel-and-go-back-to-welcome')}
                    text={t('pages.subscribe.eligibility.area-threshold.description')}
                    title={t('pages.subscribe.eligibility.area-threshold.title')}
                    titleColor="primary"
                />
            );
        }

        if (user?.is_eligible === true) {
            // ELIGIBLE
            return (
                <EndWrapper
                    handleMainButton={async () => {
                        if (isSelectableGuard.passed) {
                            setLoading(true);
                            await handleSaveProductPlan({ selectedPlanId: STANDARD_PLAN_ID });
                            setLoading(false);
                        } else {
                            navigate(isSelectableGuard.redirect);
                        }
                    }}
                    mainButtonText={t('constants.continue')}
                    mainButtonLoading={loading}
                    text={t('pages.subscribe.eligibility.eligible.description')}
                    title={t('pages.subscribe.eligibility.eligible.title')}
                    titleColor="success"
                />
            );
        }

        // This should never happen. TODO add a log to track issues
        return <Navigate to="/" replace />;
    }, [user, farmSeason, loading, continued]);

    return <CardFlow progress={80}>{eligibilityScreen()}</CardFlow>;
};

export default EligibilityStepView;
