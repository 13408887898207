import AuthService from '@services/authService/authService';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

import {
    UserAddAmbassadorDispatchTypes,
    UserAddAmbassadorSuccessDataT,
    USER_ADD_AMBASSADOR_FAILED,
    USER_ADD_AMBASSADOR_LOADING,
    USER_ADD_AMBASSADOR_SUCCESS,
} from './UserAddAmbassadorTypes';

const UserAddAmbassador =
    (ambassador_referral_code: string) => async (dispatch: Dispatch<UserAddAmbassadorDispatchTypes>) => {
        try {
            if (ambassador_referral_code !== '') {
                dispatch({ type: USER_ADD_AMBASSADOR_LOADING });

                const res: AxiosResponse<UserAddAmbassadorSuccessDataT> = await AuthService.updateAmbassador(
                    ambassador_referral_code,
                );

                dispatch({
                    type: USER_ADD_AMBASSADOR_SUCCESS,
                    payload: res.data,
                });
            }
            return { success: true };
        } catch (e) {
            const list: string[] = [];
            const error = e as Error | AxiosError;
            let message = error.toString();

            if (axios.isAxiosError(error)) {
                if (error.response?.data.message === 'Ambassador not found') {
                    list.push(error.response?.data.message);
                }
                message = error.response?.data.message;
            } else {
                message = error.message;
            }

            dispatch({
                type: USER_ADD_AMBASSADOR_FAILED,
                error: { message, list },
            });

            return {
                message,
                list,
            };
        }
    };

export default UserAddAmbassador;
