import { Box, CircularProgress, Paper, Typography, Button } from '@mui/material';
import { LogoImg } from '@soil-capital/ui-kit';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useAcceptInviteCardStyle from './AcceptInviteCard.style';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

type AcceptResponseDataT = {
    invitation: {
        id: number;
        accepted_at: string;
        registered_at: string;
    };
    is_registered: boolean;
    is_encoding: boolean;
    partner_name: string;
} | null;

type AcceptResponseErrorT = {
    response: {
        data: {
            status: 400 | 404 | 500;
            message: string;
            name: 'NotFoundError' | 'ValidationError';
            code?: 'INVALID_INVITATION_TOKEN' | 'FARM_ALREADY_LINKED_TO_PARTNER';
        };
    };
    code: string;
} | null;

function AcceptInviteCard() {
    const { classes } = useAcceptInviteCardStyle();
    const { t } = useTranslation();
    const [data, setData] = useState<AcceptResponseDataT>(null);
    const [errorMessage, setErrorMessage] = useState<{ title: string; subtitle: string } | null>(null);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const baseUrl = process.env.REACT_APP_PP_API_BASE_URL as string;
    const navigate = useNavigate();

    const redirect = (data: AcceptResponseDataT, error?: boolean) => {
        window.localStorage.removeItem('sessionToken');
        window.localStorage.removeItem('sessionExpirationTime');
        window.sessionStorage.removeItem('sessionToken');
        window.sessionStorage.removeItem('sessionExpirationTime');
        if (error || data?.is_encoding || data?.is_registered) {
            navigate(`/login?email=${encodeURIComponent(searchParams.get('email') ?? '')}`);
        } else {
            navigate(
                `/signup?token=${searchParams.get('token')}&firstName=${encodeURIComponent(
                    searchParams.get('firstName') ?? '',
                )}&lastName=${encodeURIComponent(searchParams.get('lastName') ?? '')}&email=${encodeURIComponent(
                    searchParams.get('email') ?? '',
                )}&invitationId=${id}`,
            );
        }
    };

    const fetchData = async () => {
        try {
            const result = await axios.post(`/private/invitations/farmer/${id}/accept`, null, {
                headers: {
                    Authorization: `Bearer ${searchParams.get('token')}`,
                },
                baseURL: baseUrl,
            });
            setData(result.data);
            setLoading(false);
        } catch (error) {
            setIsError(true);
            setErrorMessageOnError(error as AcceptResponseErrorT);
            setLoading(false);
        }
    };

    const onClick = () => {
        redirect(data, isError);
    };

    const setErrorMessageOnError = (error: AcceptResponseErrorT) => {
        if (error?.response?.data?.status) {
            switch (error?.response?.data?.status) {
                case 400:
                    switch (error?.response?.data?.code) {
                        case 'INVALID_INVITATION_TOKEN':
                            setErrorMessage({
                                title: t('pages.accept-invite.error.invitation-invalid-title'),
                                subtitle: t('pages.accept-invite.error.invitation-invalid-subtitle'),
                            });
                            break;
                        case 'FARM_ALREADY_LINKED_TO_PARTNER':
                            setErrorMessage({
                                title: t('pages.accept-invite.error.already-linked-to-partner-title'),
                                subtitle: t('pages.accept-invite.error.already-linked-to-partner-subtitle'),
                            });
                            break;
                        default:
                            setErrorMessage({
                                title: t('pages.accept-invite.error.server-error-title'),
                                subtitle: t('pages.accept-invite.error.server-error-subtitle'),
                            });
                            break;
                    }
                    break;
                case 404:
                    setErrorMessage({
                        title: t('pages.accept-invite.error.invitation-invalid-title'),
                        subtitle: t('pages.accept-invite.error.invitation-invalid-subtitle'),
                    });
                    break;
                case 500:
                    setErrorMessage({
                        title: t('pages.accept-invite.error.server-error-title'),
                        subtitle: t('pages.accept-invite.error.server-error-subtitle'),
                    });
                    break;
                default:
                    setErrorMessage({
                        title: t('pages.accept-invite.error.server-error-title'),
                        subtitle: t('pages.accept-invite.error.server-error-subtitle'),
                    });
                    break;
            }
        }

        if (!error?.response?.data?.status && error?.code) {
            setErrorMessage({
                title: t('pages.accept-invite.error.server-error-title'),
                subtitle: t('pages.accept-invite.error.server-error-subtitle'),
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [searchParams]);

    return (
        <Paper className={classes.container}>
            <LogoImg className={classes.logo} logoType="soilcapital" height="46px" />
            {loading ? (
                <CircularProgress className={classes.loading} size="64px" />
            ) : errorMessage ? (
                <CancelIcon className={classes.loading} color="error" />
            ) : (
                <CheckCircleIcon className={classes.loading} color="success" />
            )}
            <div className={classes.content}>
                <div className={classes.contentGroup}>
                    <div className={classes.titleContainer}>
                        <Typography align="center" variant="h4" style={{ fontSize: '24px' }}>
                            {loading
                                ? t('pages.accept-invite.loading-title')
                                : errorMessage
                                ? t(errorMessage.title)
                                : data?.is_encoding
                                ? t('pages.accept-invite.is-encoding-title')
                                : data?.is_registered
                                ? t('pages.accept-invite.is-registered-title')
                                : t('pages.accept-invite.is-not-registered-title')}
                        </Typography>
                    </div>
                    <Typography align="center" variant="h4" style={{ fontSize: '16px' }}>
                        {loading
                            ? t('pages.accept-invite.loading-subtitle')
                            : errorMessage
                            ? t(errorMessage.subtitle)
                            : data?.is_encoding
                            ? t('pages.accept-invite.is-encoding-subtitle', { partnerName: data.partner_name })
                            : data?.is_registered
                            ? t('pages.accept-invite.is-registered-subtitle', { partnerName: data.partner_name })
                            : t('pages.accept-invite.is-not-registered-subtitle', {
                                  partnerName: data?.partner_name,
                              })}
                    </Typography>
                    {!loading && (
                        <Box mt="40px">
                            <Button color="primary" fullWidth onClick={onClick}>
                                {t('constants.continue')}
                            </Button>
                        </Box>
                    )}
                </div>
            </div>
        </Paper>
    );
}

export default AcceptInviteCard;
