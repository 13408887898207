import { makeStyles } from 'tss-react/mui';

const usePaymentDoneStyle = makeStyles()(() => ({
    endWrapperOverride: {
        height: 'auto',
    },

    selector: {
        marginBottom: '30px',
        minWidth: '150px',
    },
    title: {
        fontSize: '26px',
        textAlign: 'center',
        marginTop: '40px',
    },
    body: {
        margin: '40px -10px 30px -10px',
        textAlign: 'center',
    },
}));

export default usePaymentDoneStyle;
