import { makeStyles } from 'tss-react/mui';

const useEditStepStyle = makeStyles()(() => ({
    root: {},
    title: {
        marginBottom: '10px',
    },
}));

export default useEditStepStyle;
