import { useIsSeasonClosed } from '@/hooks/useIsSeasonClosed';
import { GuardT } from '@services/guardsService/guardsService';
import selectSeasonProductPlan from '@store/selectors/selectProductPlan';
import store from '@store/store';

const productPlanIsSelectable: GuardT<'/subscribe/contract' | '/subscribe/closed'> = () => {
    const seasonProductPlan = selectSeasonProductPlan()(store.getState());
    const isSeasonClosed = useIsSeasonClosed() && process.env.REACT_APP_FORCE_SUBSCRIPTION_OPEN !== 'true';

    const isSelectable = seasonProductPlan?.status === 'to_choose';

    return {
        passed: isSelectable,
        redirect: isSeasonClosed ? '/subscribe/closed' : '/subscribe/contract',
    };
};

export default productPlanIsSelectable;
