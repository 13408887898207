import productPlanIsSelectable from '@/routers/SubscribePageRouter/productPlanIsSelectable.guard';
import getLatestProductPlanStatus from '@/store/actions/ProductPlanActions/getLatestProductPlanStatus';
import subscriptionSaveFarmSeasonProductPlan from '@actions/SubscriptionActions/SubscriptionSaveProductPlan';
import useAppDispatch from '@hooks/useAppDispatch';
import { ProductPlanT } from '@reducers/FarmSeasonReducer/FarmSeasonReducerTypes';
import { AppStateT } from '@store/store';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProductPlanStepLogicPropsT } from './ProductPlanStepTypes';

const useProductPlanStepLogic = ({ seasonProductPlan }: ProductPlanStepLogicPropsT) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const farmId = useSelector((state: AppStateT) => state.farm.currentFarm?.id);
    const farmSeasonId = useSelector((state: AppStateT) => state.farmSeason.currentFarmSeason?.id);
    const isSelectableGuard = productPlanIsSelectable();

    const [savingPlanId, setSaveLoading] = useState<ProductPlanT['id'] | null>(null);

    const handleSaveProductPlan = useCallback(
        async ({ selectedPlanId }: { selectedPlanId: ProductPlanT['id'] }) => {
            if (!selectedPlanId || !seasonProductPlan?.id) {
                throw Error(`required planId and farmSeasonProductPlanId ${selectedPlanId}, ${seasonProductPlan?.id}`);
            }

            if (!farmId || !farmSeasonId) {
                throw Error(`required farmId and farmSeasonId ${farmId}, ${farmSeasonId}`);
            }

            dispatch(getLatestProductPlanStatus({ farmId }));
            setSaveLoading(selectedPlanId);
            const farmSeasonProductPlan = await dispatch(
                subscriptionSaveFarmSeasonProductPlan({
                    farmId,
                    farmSeasonId,
                    productPlanId: selectedPlanId,
                    farmSeasonProductPlanId: seasonProductPlan.id,
                }),
            );
            setSaveLoading(selectedPlanId);
            if (!farmSeasonProductPlan) {
                return;
            }
            navigate(isSelectableGuard.redirect);
        },
        [seasonProductPlan?.id],
    );

    return { handleSaveProductPlan, savingPlanId };
};

export default useProductPlanStepLogic;
