import { GuardT } from '@services/guardsService/guardsService';
import selectIsProductPlanActive from '@store/selectors/selectIsProductPlanActive';
import store from '@store/store';

/** we consider a plan as activable if is already considered actif or if it's a BASIC plan who can be passed to active. */
const productPlanIsActivable: GuardT<'/subscribe/farm'> = () => {
    // const seasonProductPlan = selectSeasonProductPlan()(store.getState());
    const isActif = selectIsProductPlanActive()(store.getState());
    const isProductPlanActivable = isActif;
    // || (seasonProductPlan?.product_plan?.name === 'BASIC' && seasonProductPlan?.status === 'to_choose');

    return {
        passed: isProductPlanActivable,
        redirect: '/subscribe/farm',
    };
};

export default productPlanIsActivable;
