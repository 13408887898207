import getUser from '@/store/actions/AuthActions/GetUserActions';
import { useAcceptInviteMutation, useRejectInviteMutation } from '@api/invitation.api';
import { setPendingInvitation } from '@api/invitation.slice';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppNavigate from '@hooks/useAppNavigate';
import { Button, CircularProgress, Container } from '@mui/material';
import TopBar from '@pages/HomePage/components/TopBar/TopBar';
import { useInvitationFromPartnerStyles } from '@pages/InvitationFromPartnerPage/InvitationFromPartner.style';
import { PartnerInvitationCard } from '@pages/InvitationFromPartnerPage/PartnerInvitationCard/PartnerInvitationCard';
import { AppStateT } from '@store/store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const InvitationFromPartnerPage = () => {
    const { classes } = useInvitationFromPartnerStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const invitationState = useSelector((state: AppStateT) => state.invitation);
    const partnerName = invitationState.pendingPartnerInvitation?.partner?.name as string;
    const isFromArgentina = invitationState.pendingPartnerInvitation?.partner?.country_id === 2;
    const invitationId = invitationState.pendingPartnerInvitation?.id as number;
    const fromInvitationEmail = invitationState.fromInvitationEmail;
    const [acceptInvite, { isLoading: isLoadingAccept }] = useAcceptInviteMutation();
    const [rejectInvite, { isLoading: isLoadingReject }] = useRejectInviteMutation();
    const isLoadingMutation = isLoadingAccept || isLoadingReject;
    const navigate = useAppNavigate();
    const dispatch = useAppDispatch();

    const handleAccept = async () => {
        try {
            await acceptInvite({ invitationId, fromInvitationEmail }).unwrap();
            enqueueSnackbar(t('pages.partner-invite.toasts.accept', { partnerName: partnerName }), {
                variant: 'success',
            });

            dispatch(getUser());
            dispatch(setPendingInvitation(null));
            if (isFromArgentina) {
                navigate('/subscribe/farm');
                return;
            }
            navigate('/');
        } catch (e) {
            enqueueSnackbar(t('pages.partner-invite.toasts.error'), {
                variant: 'error',
            });
        }
    };

    const handleRefuse = async () => {
        try {
            await rejectInvite({ invitationId, fromInvitationEmail }).unwrap();
            enqueueSnackbar(t('pages.partner-invite.toasts.reject'), {
                variant: 'success',
            });
            dispatch(setPendingInvitation(null));
            navigate('/');
        } catch (e) {
            enqueueSnackbar(t('pages.partner-invite.toasts.error'), {
                variant: 'error',
            });
        }
    };

    return (
        <>
            <Container className={classes.container} maxWidth="xl">
                <div className={classes.background}>
                    <div className={classes.darkBackground} />
                    <div className={classes.coloredContainer} />
                </div>
                <div className={classes.content}>
                    <div>
                        <TopBar displayContextSelector={false} disableNotification />
                    </div>
                    <PartnerInvitationCard
                        partnerName={partnerName}
                        actions={
                            <>
                                <Button disabled={isLoadingMutation} onClick={handleAccept} size="small">
                                    {isLoadingAccept ? <CircularProgress size={14} /> : t('constants.accept')}
                                </Button>
                                <Button
                                    disabled={isLoadingMutation}
                                    onClick={handleRefuse}
                                    size="small"
                                    className={classes.secondaryAction}
                                >
                                    {isLoadingReject ? <CircularProgress size={14} /> : t('constants.refuse')}
                                </Button>
                            </>
                        }
                    />
                </div>
            </Container>
        </>
    );
};
