import SelectionCardView from '@components/SelectionCard/SelectionCardView';
import useAppNavigate from '@hooks/useAppNavigate';
import pictoCrops from '@images/picto-crops.jpg';
import { checkEmailIsVerified } from '@services/guardsService/guardsService';
import SegmentService from '@services/segmentService/segmentService';
import { AppStateT } from '@store/store';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useIsSeasonClosed } from '@/hooks/useIsSeasonClosed';
import selectIsProductPlanActive from '@store/selectors/selectIsProductPlanActive';
import { SelectionStepViewPropsT } from './SelectionStepTypes';

const SelectionStepView = ({ user, loading }: SelectionStepViewPropsT): JSX.Element => {
    const farmSeason = useSelector((store: AppStateT) => store.farmSeason.currentFarmSeason);
    const isProductPlanActive = useSelector(selectIsProductPlanActive());

    const navigate = useAppNavigate();
    const { t } = useTranslation();
    let i18nKey = '';
    let buttonLabel = '';
    let headlineTooltipSlug = '';

    const isNotEligible = user?.is_eligible === false;
    const hasSelectedSeason = !!farmSeason?.season_id;
    const emailVerified = checkEmailIsVerified().passed;

    const hasRegistered = !!farmSeason?.id;

    if (!isProductPlanActive && hasSelectedSeason) {
        i18nKey = 'pages.subscribe.headline.access-revoked';
    } else if (!emailVerified) {
        i18nKey = 'pages.verify-email.blocking-text';
    }

    if (isNotEligible && hasRegistered && !hasSelectedSeason) {
        headlineTooltipSlug = 'pages.subscribe.headline.not-eligible';
    } else if (!isProductPlanActive && hasSelectedSeason) {
        headlineTooltipSlug = 'pages.subscribe.headline.access-revoked';
    } else if (!emailVerified) {
        headlineTooltipSlug = 'pages.verify-email.blocking-text';
    }

    if (!isProductPlanActive && hasSelectedSeason) {
        buttonLabel = t('pages.subscribe.subscribe-card.access-revoked');
    } else {
        buttonLabel = t('pages.subscribe.subscribe-card.button-label-register');
    }

    const withHeadlines = !isProductPlanActive && ((isNotEligible && hasRegistered) || hasSelectedSeason);

    // if plan !active and season selected -> disable
    const disabledButton = !isProductPlanActive && (hasSelectedSeason || !emailVerified);
    // FP-4004
    const temporaryHideSubscription = useIsSeasonClosed();
    const type = isNotEligible || (!isProductPlanActive && hasSelectedSeason) ? 'error' : 'success';

    // FP-4344
    const description: string = temporaryHideSubscription
        ? t('pages.encoding-blocked.description')
        : t('pages.subscribe.subscribe-card.description');
    const title: string = temporaryHideSubscription
        ? t('pages.encoding-blocked.title')
        : t('pages.subscribe.subscribe-card.title');

    const subscribe = async () => {
        // MSC-1886
        SegmentService.eligibilityStartedTrack();
        navigate('/subscribe/farm');
    };

    return (
        <>
            <SelectionCardView
                buttonLabel={buttonLabel}
                buttonTooltipSlug={i18nKey}
                description={description}
                disabled={disabledButton}
                headline={{
                    type,
                    i18nKey: headlineTooltipSlug,
                }}
                image={pictoCrops}
                loading={loading}
                title={title}
                withHeadline={withHeadlines}
                inverseColor
                onClick={subscribe}
                hideButton={temporaryHideSubscription}
            />
        </>
    );
};

export default SelectionStepView;
