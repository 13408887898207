/* eslint-disable @typescript-eslint/no-explicit-any */
import { Trans, useTranslation } from 'react-i18next';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import { Divider, Typography } from '@mui/material';
import useLoader from '@hooks/useLoader';

import CardFlow from '../components/CardFlow/CardFlow';
import ProgressWrapper from '../components/ProgressWrapper/ProgressWrapper';

import useContractStepStyle from './ContractStep.style';
import useContractStepLogic from './ContractStep.logic';
import {
    CONTRACT_ANNEX_DATE,
    CONTRACT_DATE,
    CONTRACT_LANGUAGES,
    CONTRACT_COUNTRIES,
} from '@constants/terms-and-condition';

const ContractStep = (): JSX.Element => {
    const { t } = useTranslation();
    const {
        handleSaveTermsAndConditions,
        loadingSave,
        loadingGetApprovals,
        approvals,
        setApproval,
        countryIso,
        isArgentinianFarm,
        isFrenchFarm,
        isBelgianFarm,
    } = useContractStepLogic();
    const { classes } = useContractStepStyle();
    const { i18n } = useTranslation();
    const langKey = (CONTRACT_LANGUAGES as any).includes(i18n.language) ? i18n.language : CONTRACT_LANGUAGES[0];
    const contractKey =
        isBelgianFarm && ['nl', 'fr'].includes(langKey)
            ? langKey
            : (CONTRACT_COUNTRIES as any).includes(countryIso)
            ? countryIso
            : CONTRACT_COUNTRIES[0];

    const termsAndConditionsPdfLink = `/terms-and-conditions/${CONTRACT_DATE}-${contractKey?.toLocaleLowerCase()}.pdf`;
    const landownerApprovalPdfLink = `/terms-and-conditions/${CONTRACT_ANNEX_DATE}-${contractKey?.toLocaleLowerCase()}-annex3.pdf`;

    const { containerRef: loaderApprovalContainer } = useLoader(loadingGetApprovals, { bgColor: '#fff' });

    return (
        <CardFlow progress={20}>
            <ProgressWrapper
                handleNextButton={handleSaveTermsAndConditions}
                nextButtonDisabled={!approvals?.approval}
                nextButtonLoading={loadingSave}
                nextButtonText={t('constants.accept')}
                subTitle={t('pages.subscribe.contract.sub-title')}
                title={t('pages.subscribe.contract.title')}
                showBackLink
            >
                <div ref={loaderApprovalContainer} className={classes.contractContainer}>
                    <div className={classes.contractText}>
                        {/* T&C checkboxes */}
                        <LabelCheckboxView
                            checked={approvals?.approval || false}
                            label={
                                <>
                                    <Trans
                                        components={{
                                            pdfLink: (
                                                <a
                                                    className={classes.link}
                                                    aria-hidden
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(termsAndConditionsPdfLink, '_blank');
                                                    }}
                                                />
                                            ),
                                        }}
                                        defaults="Agree to <pdfLink>terms of use</pdfLink>"
                                        i18nKey="pages.subscribe.contract.terms-and-condition"
                                    />
                                    <span className={classes.requiredStar}>*</span>
                                </>
                            }
                            noMargin
                            required
                            onChange={(e, checked) => setApproval('approval', checked)}
                        />
                        {!isArgentinianFarm && (
                            <LabelCheckboxView
                                checked={approvals?.land_owner_approval || false}
                                label={
                                    <Trans
                                        components={{
                                            pdfLink: (
                                                <a
                                                    className={classes.link}
                                                    aria-hidden
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(landownerApprovalPdfLink, '_blank');
                                                    }}
                                                />
                                            ),
                                        }}
                                        defaults="I need to provide <pdfLink>landowner approval</pdfLink> before my first carbon payment"
                                        i18nKey="pages.subscribe.contract.landowner-approval"
                                    />
                                }
                                noMargin
                                onChange={(e, checked) => setApproval('land_owner_approval', checked)}
                            />
                        )}

                        {isFrenchFarm && (
                            <>
                                <Divider className={classes.divider} />
                                {/* Crops to exclude from program */}
                                <Typography variant="body1">
                                    {t('pages.subscribe.contract.exclude-crop-description')}
                                </Typography>
                                <Typography variant="body1">
                                    {t('pages.subscribe.contract.exclude-crop-label')}:
                                </Typography>
                                <LabelCheckboxView
                                    checked={approvals?.exclude_rapeseed || false}
                                    label={t('pages.subscribe.contract.exclude-crop-rapeseed')}
                                    noMargin
                                    onChange={(e, checked) => setApproval('exclude_rapeseed', checked)}
                                />
                                <LabelCheckboxView
                                    checked={approvals?.exclude_sunflower || false}
                                    label={t('pages.subscribe.contract.exclude-crop-sunflower')}
                                    noMargin
                                    onChange={(e, checked) => setApproval('exclude_sunflower', checked)}
                                />
                            </>
                        )}
                    </div>
                </div>
            </ProgressWrapper>
        </CardFlow>
    );
};

export default ContractStep;
