import signUp from '@actions/AuthActions/SignUpActions';
import { ValidationFieldPropsT } from '@components/ValidationField/ValidationFieldTypes';
import useAppDispatch from '@hooks/useAppDispatch';
import { acceptedThroughEmailInvitation } from '@api/invitation.slice';
import currentLanguageSelector from '@store/selectors/currentLanguageSelector';
import { AppStateT } from '@store/store';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import SignUpPageView from './SignUpPageView';

const SignUpPage = (): JSX.Element => {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    const loadingState = useSelector((state: AppStateT) => state.auth.loading);
    const currentLanguage = useSelector(currentLanguageSelector());
    const auth0Loading = useSelector((state: AppStateT) => state.auth.auth0Loading);

    const { t } = useTranslation();

    const [passwordRules, setPasswordRules] = useState<ValidationFieldPropsT['rules']>([]);

    const minSpecialCharacterMatch = /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/;
    const minDigitMatch = /^(?=.*[0-9])/;
    const minLength = 8;

    const alreadyTakenEmailError = useSelector((state: AppStateT) =>
        state.auth.error?.list?.find((err) => err === 'register-email-already-taken')
            ? t('pages.signup.the-email-has-already-been-taken')
            : undefined,
    );

    const wrongAmbassadorCodeError = useSelector((state: AppStateT) =>
        state.auth.error?.list?.find((err) => err === 'register.referral_code.not_found')
            ? t('pages.signup.referral_code_not_found')
            : undefined,
    );

    const validationSchema = yup.object({
        first_name: yup
            .string()
            .min(2, t('validation.too-short'))
            .max(50, t('validation.too-long'))
            .required(t('validation.no-first-name-provided')),
        last_name: yup
            .string()
            .min(2, t('validation.too-short'))
            .max(50, t('validation.too-long'))
            .required(t('validation.no-last-name-provided')),
        email: yup.string().email(t('validation.invalid-email')).required(t('validation.no-email-provided')),
        password: yup
            .string()
            .required(t('validation.no-password-provided'))
            .min(minLength, t('validation.password-must-be-at-least-x-characters', { min: minLength }))
            .matches(minDigitMatch, t('validation.password-must-at-least-contain-one-figure'))
            .matches(minSpecialCharacterMatch, t('validation.password-must-at-least-contain-one-special-character')),
        terms: yup.bool().oneOf([true], t('validation.signup.terms-accept')),
        referral_code: yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            first_name: searchParams.get('firstName') ?? '',
            last_name: searchParams.get('lastName') ?? '',
            email: searchParams.get('email') ?? '',
            referral_code: '',
            password: '',
            terms: false,
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: async ({ email, first_name, last_name, password, referral_code }, { setSubmitting }) => {
            await dispatch(
                signUp(
                    { email: email.toLowerCase(), first_name, last_name, password, referral_code },
                    currentLanguage.id || 1,
                    searchParams.get('token') ?? undefined,
                    Number(searchParams.get('invitationId')) ?? undefined,
                ),
            );
            setSubmitting(false);
        },
    });

    useEffect(() => {
        setPasswordRules([
            {
                isValid: formik.values.password?.length >= minLength,
                text: t('validation.password-must-be-at-least-x-characters-short', { min: minLength }),
            },
            {
                isValid: minDigitMatch.test(formik.values.password),
                text: t('validation.password-must-at-least-contain-one-figure-short'),
            },
            {
                isValid: minSpecialCharacterMatch.test(formik.values.password),
                text: t('validation.password-must-at-least-contain-one-special-character-short'),
            },
        ]);
    }, [formik.values.password]);

    useEffect(() => {
        if (alreadyTakenEmailError) formik.setErrors({ ...formik.errors, email: alreadyTakenEmailError });
        if (wrongAmbassadorCodeError) formik.setErrors({ ...formik.errors, referral_code: wrongAmbassadorCodeError });
    }, [alreadyTakenEmailError, wrongAmbassadorCodeError]);

    useEffect(() => {
        dispatch(acceptedThroughEmailInvitation(searchParams.has('email')));
    }, []);

    return (
        <SignUpPageView
            auth0Loading={auth0Loading}
            formik={formik}
            loading={loadingState}
            passwordRules={passwordRules}
        />
    );
};

export default SignUpPage;
