import { Dispatch } from '@reduxjs/toolkit';
import { AppActionsT } from '../AppActions/AppActionsTypes';

export const GET_FARM_SEASON_CROPS_LOADING = 'GET_FARM_SEASON_CROPS_LOADING';
export const GET_FARM_SEASON_CROPS_FAILED = 'GET_FARM_SEASON_CROPS_FAILED';
export const GET_FARM_SEASON_CROPS_SUCCESS = 'GET_FARM_SEASON_CROPS_SUCCESS';

export type CropT = {
    id: number;
    translation_slug?: string;
    name?: string;
};

export type FarmSeasonCropT = {
    id?: number | null;
    yield?: string | null;
    is_accompanied?: boolean | null;
    crop: CropT;
};

export type GetFarmSeasonCropsResponseDataT = {
    crops: FarmSeasonCropT[];
};

export type FarmSeasonCropsGetCallBackT = (
    dispatch: Dispatch<AppActionsT>,
) => Promise<GetFarmSeasonCropsResponseErrorT>;

export type GetFarmSeasonCropsResponseErrorT = {
    message?: string;
    list?: string[];
};

export type GetFarmSeasonCropsLoadingT = {
    type: typeof GET_FARM_SEASON_CROPS_LOADING;
};

export type GetFarmSeasonCropsFailedT = {
    type: typeof GET_FARM_SEASON_CROPS_FAILED;
    error: GetFarmSeasonCropsResponseErrorT;
};

export type GetFarmSeasonCropsSuccessT = {
    type: typeof GET_FARM_SEASON_CROPS_SUCCESS;
    payload: GetFarmSeasonCropsResponseDataT;
};

export type GetFarmSeasonCropsDispatchTypesT =
    | GetFarmSeasonCropsLoadingT
    | GetFarmSeasonCropsFailedT
    | GetFarmSeasonCropsSuccessT;
