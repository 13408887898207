import { FarmSeasonFieldT } from '@reducers/FarmSeasonFieldReducer/FarmSeasonFieldReducerTypes';
import { Dispatch } from '@reduxjs/toolkit';

export const PUT_FARM_SEASON_CROP_LOADING = 'PUT_FARM_SEASON_CROP_LOADING';
export const PUT_FARM_SEASON_CROP_FAILED = 'PUT_FARM_SEASON_CROP_FAILED';
export const PUT_FARM_SEASON_CROP_SUCCESS = 'PUT_FARM_SEASON_CROP_SUCCESS';

export type CropT = {
    id: number;
    translation_slug?: string;
    name?: string;
};

export type FarmSeasonCropT = {
    id?: number | null;
    yield?: string | null;
    is_accompanied?: boolean | null;
    crop: CropT;
};

export type PutFarmSeasonCropDataT = FarmSeasonCropT;

export type PutFarmSeasonCropResponseDataT = {
    crop: FarmSeasonCropT;
    fields: FarmSeasonFieldT[];
};

export type FarmSeasonCropPutCallBackT = (
    dispatch: Dispatch<PutFarmSeasonCropDispatchTypesT>,
) => Promise<PutFarmSeasonCropResponseErrorT>;

export type PutFarmSeasonCropResponseErrorT = {
    message?: string;
    list?: string[];
};

export type PutFarmSeasonCropLoadingT = {
    type: typeof PUT_FARM_SEASON_CROP_LOADING;
};

export type PutFarmSeasonCropFailedT = {
    type: typeof PUT_FARM_SEASON_CROP_FAILED;
    error: PutFarmSeasonCropResponseErrorT;
};

export type PutFarmSeasonCropSuccessT = {
    type: typeof PUT_FARM_SEASON_CROP_SUCCESS;
    payload: PutFarmSeasonCropResponseDataT;
};

export type PutFarmSeasonCropDispatchTypesT =
    | PutFarmSeasonCropLoadingT
    | PutFarmSeasonCropFailedT
    | PutFarmSeasonCropSuccessT;
