import FarmSeasonProductPlanApiService from '@services/productPlanApiService/productPlanApiService';
import { ProductPlanGetLatestStatusDataT } from '@services/productPlanApiService/productPlanApiServiceTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { ProductPlanDispatchTypes } from './ProductPlanActions';
import { GET_LATEST_PODUCT_PLAN_STATUS_SUCCESS } from './getLatestProductPlanStatusTypes';

export type farmSeasonGetProductPlanStatusPropsT = {
    farmSeasonProductPlan: number;
    farmId: number;
    farmSeasonId: number;
};

const getLatestProductPlanStatus =
    ({ farmId }: ProductPlanGetLatestStatusDataT) =>
    async (dispatch: Dispatch<ProductPlanDispatchTypes>) => {
        try {
            const resp = await FarmSeasonProductPlanApiService.getLatestStatus({
                farmId,
            });
            dispatch({
                type: GET_LATEST_PODUCT_PLAN_STATUS_SUCCESS,
                payload: { status: resp.data.status, farm_season_id: resp.data.farm_season_id },
            });
            return resp.data;
        } catch {
            return false;
        }
    };

export default getLatestProductPlanStatus;
