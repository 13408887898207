import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import LivestockInputContainerView from '@pages/HomePage/Installation/InstallationTasks/LivestocksTask/LivestockListView/LivestockListView';
import AddButtonView from '@components/AddButton/AddButtonView';
import { FieldArray, FormikProvider, getIn } from 'formik';
import ErrorMessageView from '@components/ErrorMessage/ErrorMessageView';

import CardFlow from '../components/CardFlow/CardFlow';
import ProgressWrapper from '../components/ProgressWrapper/ProgressWrapper';

import useLivestockStepStyle from './LivestockStepView.style';
import { LivestockStepViewPropsT } from './LivestockStepTypes';
import LoadingSection from '@components/LoadingSection/LoadingSection';

const LivestockStepView = ({
    formik,
    serverError,
    submitLoading,
    getLivestockLoading,
}: LivestockStepViewPropsT): JSX.Element => {
    const { classes } = useLivestockStepStyle();
    const { t } = useTranslation();

    return (
        <CardFlow progress={60}>
            <ProgressWrapper
                handleNextButton={formik.submitForm}
                nextButtonLoading={submitLoading}
                nextButtonText={t('constants.continue')}
                nextButtonDisabled={getLivestockLoading}
                subTitle={t('pages.subscribe.livestock.sub-title')}
                title={t('pages.subscribe.livestock.title')}
                showBackLink
            >
                <LoadingSection loading={getLivestockLoading}>
                    <Grid
                        className={classes.gridContainer}
                        spacing={2}
                        style={{ justifyContent: 'flex-end' }}
                        container
                    >
                        <FormikProvider value={formik}>
                            <FieldArray
                                name="livestocks"
                                render={(arrayHelpers) => (
                                    <>
                                        {serverError !== '' && (
                                            <Grid xs={12} item>
                                                <ErrorMessageView message={serverError} />
                                            </Grid>
                                        )}
                                        {formik.values.livestocks.map((livestock, index) => {
                                            const key = `livestocks.${index}.key`;
                                            const quantityName = `livestocks.${index}.quantity`;
                                            const touchedQuantity = getIn(formik.touched, quantityName);
                                            const errorQuantity = getIn(formik.errors, quantityName);
                                            const livestockIdName = `livestocks.${index}.livestock_id`;
                                            const touchedLivestock = getIn(formik.touched, livestockIdName);
                                            const errorLivestock = getIn(formik.errors, livestockIdName);
                                            const title = `${t(
                                                'pages.installationtasks.livestock-task.livestock-index',
                                            )} ${index + 1}`;

                                            return (
                                                <Grid key={key} xs={12} item>
                                                    <LivestockInputContainerView
                                                        livestockError={Boolean(touchedLivestock && errorLivestock)}
                                                        livestockHelperText={
                                                            touchedLivestock && errorLivestock ? errorLivestock : ''
                                                        }
                                                        livestockId={livestock.livestock_id}
                                                        quantity={livestock.quantity}
                                                        quantityError={Boolean(touchedQuantity && errorQuantity)}
                                                        quantityHelperText={
                                                            touchedQuantity && errorQuantity ? errorQuantity : ''
                                                        }
                                                        quantityName={quantityName}
                                                        title={title}
                                                        usedLivestock={formik.values.livestocks}
                                                        onClose={() => arrayHelpers.remove(index)}
                                                        onLivestockChange={(livestockId) =>
                                                            formik.setFieldValue(livestockIdName, livestockId)
                                                        }
                                                        onQuantityChange={(value) =>
                                                            formik.setFieldValue(quantityName, value)
                                                        }
                                                    />
                                                </Grid>
                                            );
                                        })}
                                        {formik.touched.livestocks && typeof formik.errors.livestocks === 'string' && (
                                            <Grid xs={12} item>
                                                <ErrorMessageView message={formik.errors.livestocks} />
                                            </Grid>
                                        )}
                                        <Grid xs={12} item>
                                            <AddButtonView
                                                label={t(
                                                    'pages.installationtasks.livestock-task.add-new-livestock-type',
                                                )}
                                                onClick={() => arrayHelpers.push({ quantity: '', livestock_id: '' })}
                                            />
                                        </Grid>
                                    </>
                                )}
                            />
                        </FormikProvider>
                    </Grid>
                </LoadingSection>
            </ProgressWrapper>
        </CardFlow>
    );
};

export default LivestockStepView;
