import { RESET } from '@reducers/RootTypes';
import { client } from '@services/apiService/apiService';
import authService from '@services/authService/authService';
import { isAssociateUser } from '@services/guardsService/guardsService';
import i18next from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { Dispatch } from 'redux';
import { AppActionsT } from '../AppActions/AppActionsTypes';
import { LOGOUT_SUCCESS } from './LogoutActionsTypes';
import { clearStorage } from '@/utils/clearStorage';

const logout =
    () =>
    async (dispatch: Dispatch<AppActionsT>): Promise<void> => {
        authService.logout(client.defaults.headers.common.Authorization as string);
        clearStorage();
        dispatch({ type: RESET });
        dispatch({ type: LOGOUT_SUCCESS });
    };

export const logoutFarmerOrRedirectAgro = () => {
    const { passed } = isAssociateUser();
    if (passed) {
        return (dispatch: Dispatch<AppActionsT>) => {
            dispatch({ type: 'ROUTING_REDIRECT', payload: { route: '/search' } });
            enqueueSnackbar(i18next.t<string>('errors.fetch-main-data'), { variant: 'error' });
        };
    }

    return logout();
};

export default logout;
