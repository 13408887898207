import LabelTextField from '@components/LabelTextField/LabelTextField';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import LabelNumberField from '@components/LabelNumberField/LabelNumberField';
import ErrorMessageView from '@components/ErrorMessage/ErrorMessageView';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';

import CardFlow from '../components/CardFlow/CardFlow';
import ProgressWrapper from '../components/ProgressWrapper/ProgressWrapper';

import useFarmStepStyle from './FarmStepView.style';
import { FarmStepViewPropsT } from './FarmStepTypes';

const FarmStepView = ({ formik, countries, serverError, submitLoading }: FarmStepViewPropsT): JSX.Element => {
    const { classes } = useFarmStepStyle();
    const { t } = useTranslation();
    const { handleChange, touched, errors, values } = formik;

    return (
        <CardFlow progress={40}>
            <ProgressWrapper
                handleNextButton={formik.submitForm}
                nextButtonLoading={submitLoading}
                nextButtonText={t('constants.continue')}
                nextButtonUrl="/subscribe/eligibility"
                showBackLink={false}
                subTitle={t('pages.subscribe.farm.sub-title')}
                title={t('pages.subscribe.farm.title')}
            >
                <Grid className={classes.gridContainer} spacing={2} container>
                    <Grid xs={6} item>
                        <LabelTextField
                            autoFocus={values.name === ''}
                            error={touched.name && !!errors.name}
                            helperText={touched.name && errors.name}
                            label={t('pages.subscribe.farm.your-farm-name')}
                            name="name"
                            value={values.name}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <LabelNumberField
                            andormentPosition="end"
                            andormentText={t('constants.hectare-unit')}
                            error={touched.eligibility_area && !!errors.eligibility_area}
                            helperText={touched.eligibility_area ? errors.eligibility_area : undefined}
                            label={t('pages.subscribe.farm.crop-land-area')}
                            name="eligibility_area"
                            value={values.eligibility_area && Number(values.eligibility_area)}
                            variant="outlined"
                            fullWidth
                            required
                            withHelp
                            onChange={(eligibility_area) => formik.setFieldValue('eligibility_area', eligibility_area)}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <LabelTextField
                            error={touched.address && !!errors.address}
                            helperText={touched.address && errors.address}
                            label={t('pages.subscribe.farm.your-farm-address')}
                            name="address"
                            value={values.address}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <LabelTextField
                            error={touched.postal_code && !!errors.postal_code}
                            helperText={touched.postal_code && errors.postal_code}
                            label={t('pages.subscribe.farm.postal-code')}
                            name="postal_code"
                            value={values.postal_code}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <LabelTextField
                            error={touched.city && !!errors.city}
                            helperText={touched.city && errors.city}
                            label={t('pages.subscribe.farm.city')}
                            name="city"
                            value={values.city}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <LabelAutocomplete
                            error={touched.country && !!errors.country}
                            helperText={touched.country ? errors.country : undefined}
                            label={
                                <span>
                                    {t('pages.subscribe.farm.country').slice(0, 3)}&zwnj;
                                    {t('pages.subscribe.farm.country').slice(3)}
                                </span>
                            }
                            options={countries}
                            value={values.country?.id || ''}
                            required
                            onChange={(countryId) =>
                                formik.setFieldValue(
                                    'country',
                                    countries.find((country) => country.id === Number(countryId)) || null,
                                )
                            }
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <LabelTextField
                            error={touched.phone_number && !!errors.phone_number}
                            helperText={touched.phone_number && errors.phone_number}
                            id="phone"
                            label={t('pages.subscribe.farm.phone-number')}
                            name="phone_number"
                            value={values.phone_number}
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => {
                                formik.setFieldValue('phone_number', e.target.value.split(' ').join(''));
                            }}
                        />
                    </Grid>
                    <Grid className={classes.align} xs={6} item>
                        <LabelCheckboxView
                            checked={formik.values.eligibility_has_livestock}
                            label={t('pages.subscribe.farm.has_livestock')}
                            noMargin
                            onChange={(e, eligibility_has_livestock) =>
                                formik.setFieldValue('eligibility_has_livestock', eligibility_has_livestock)
                            }
                        />
                    </Grid>
                    {serverError !== '' && (
                        <Grid xs={12} item>
                            <ErrorMessageView message={serverError} />
                        </Grid>
                    )}
                </Grid>
            </ProgressWrapper>
        </CardFlow>
    );
};

export default FarmStepView;
