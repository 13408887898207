import { Container } from '@mui/material';
import TopBar from '@pages/HomePage/components/TopBar/TopBar';
import SubscribePageRouter from '@routers/SubscribePageRouter/SubscribePageRouter';
import useTopBanner from '@hooks/useTopBanner';

import useSubscribePageViewStyle from './SubscribePageView.style';

const SubscribePageView = (): JSX.Element => {
    const { showTopBanner, topBanner } = useTopBanner();
    const { classes } = useSubscribePageViewStyle({ showTopBanner });

    return (
        <>
            {topBanner}
            <Container className={classes.container} maxWidth="xl">
                <div className={classes.background}>
                    <div className={classes.darkBackground} />
                    <div className={classes.imageContainer} />
                </div>
                <div className={classes.content}>
                    <div className={classes.appBarWrapper}>
                        <TopBar displayContextSelector={false} disableNotification />
                    </div>
                    <div className={classes.cardContainer}>
                        <SubscribePageRouter />
                    </div>
                </div>
            </Container>
        </>
    );
};

export default SubscribePageView;
