import { Grid, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import LabelNumberField from '@components/LabelNumberField/LabelNumberField';
import LabelCheckboxView from '@components/LabelCheckbox/LabelCheckboxView';
import TwoButtonFooterView from '@components/TwoButtonFooter/TwoButtonFooterView';
import LabelAutocomplete from '@components/LabelAutocomplete/LabelAutocomplete';

import useEditStepStyle from './EditStep.style';
import Restricted from '@components/Restricted/Restricted';
import LoadingButton from '@components/LoadingButton/LoadingButton';
import RestrictSaveFarmSeason from '@components/RestrictSaveFarmSeason/RestrictSaveFarmSeason';
import useEditStepLogic from './EditStep.logic';
import { EditStepLogicPropsT } from './EditStep.types';

const EditStep = (props: EditStepLogicPropsT): JSX.Element => {
    const { classes } = useEditStepStyle();
    const { t } = useTranslation();
    const theme = useTheme();

    const { formik, loading, hasId, restrictEditFarmSeasonProps, staticCrops, isCFT } = useEditStepLogic(props);

    return (
        <>
            <Typography className={classes.title} color="secondary" variant="h4">
                {hasId ? (
                    <Trans i18nKey="pages.crops.edit-step.modify-title">
                        Modifier votre <span style={{ color: theme.palette.secondary.dark }}>culture</span>
                    </Trans>
                ) : (
                    <Trans i18nKey="pages.crops.edit-step.create-title">
                        Créer votre <span style={{ color: theme.palette.secondary.dark }}>culture</span>
                    </Trans>
                )}
            </Typography>
            <form id="crop-form">
                <Grid spacing={2} container>
                    <Grid xs={7} item>
                        <Restricted {...restrictEditFarmSeasonProps}>
                            <LabelAutocomplete
                                error={formik.touched.crop_id && !!formik.errors.crop_id}
                                helpName="crop-select-help"
                                helperText={formik.touched.crop_id ? formik.errors.crop_id : undefined}
                                label={t('pages.crops.edit-step.crop')}
                                options={staticCrops}
                                value={formik.values.crop_id || ''}
                                required
                                withHelp
                                onChange={(cropId) => formik.setFieldValue('crop_id', cropId && cropId)}
                            />
                        </Restricted>
                    </Grid>

                    {isCFT && (
                        <>
                            <Grid xs={5} item>
                                <Restricted {...restrictEditFarmSeasonProps}>
                                    <LabelNumberField
                                        andormentPosition="end"
                                        andormentText="t/ha"
                                        error={formik.touched.yield && !!formik.errors.yield}
                                        helpName="yield-help"
                                        helperText={formik.touched.yield ? formik.errors.yield : undefined}
                                        label={t('pages.crops.edit-step.yield')}
                                        name="yield"
                                        value={formik.values.yield ? Number(formik.values.yield) : ''}
                                        variant="outlined"
                                        required
                                        withHelp
                                        onChange={(value) => formik.setFieldValue('yield', value.toString())}
                                    />
                                </Restricted>
                            </Grid>

                            <Grid xs={12} item>
                                <Restricted {...restrictEditFarmSeasonProps}>
                                    <LabelCheckboxView
                                        checked={formik.values.is_accompanied || false}
                                        error={formik.touched.is_accompanied && !!formik.errors.is_accompanied}
                                        helperText={
                                            formik.touched.is_accompanied ? formik.errors.is_accompanied : undefined
                                        }
                                        label={t('pages.crops.edit-step.has-relation-crop')}
                                        name="is_accompanied"
                                        onChange={(event) =>
                                            formik.setFieldValue('is_accompanied', event.target.checked)
                                        }
                                    />
                                </Restricted>
                            </Grid>
                        </>
                    )}
                </Grid>
            </form>
            <TwoButtonFooterView
                rightOnCLick={() => formik.submitForm()}
                rightButtonOverride={
                    <RestrictSaveFarmSeason>
                        <LoadingButton
                            loading={loading}
                            text={t('constants.save')}
                            onClick={() => formik.submitForm()}
                        />
                    </RestrictSaveFarmSeason>
                }
            />
        </>
    );
};

export default EditStep;
